@if (canShow) {
  <div class="root">
    <div class="menu_item" routerLink="/money" routerLinkActive="active">
      <div class="icon">
        <fa-icon icon="wallet" size="lg" />
      </div>
      <div class="text">お金の管理</div>
    </div>
    <div class="menu_item" routerLink="/game" routerLinkActive="active">
      <div class="icon">
        <fa-icon icon="gamepad" size="lg" />
      </div>
      <div class="text">ゲーム</div>
    </div>
    <div class="menu_item" routerLink="/unknown" routerLinkActive="active">
      <div class="icon">
        <fa-icon icon="circle-question" size="lg" />
      </div>
      <div class="text">未定！</div>
    </div>
    <div class="menu_item" routerLink="/account" routerLinkActive="active">
      <div class="icon">
        <fa-icon icon="user" size="lg" />
      </div>
      <div class="text">アカウント</div>
    </div>
  </div>
}
