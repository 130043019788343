import { CommonModule } from "@angular/common"
import { Component, DestroyRef, inject } from "@angular/core"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { FormsModule } from "@angular/forms"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { Subject, debounceTime } from "rxjs"

import { BaseTextInputComponent } from "../base-text-input/base-text-input.component"

@Component({
  selector: "c-search-form",
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule],
  templateUrl: "../base-text-input/base-text-input.component.html",
  styleUrls: [
    "../base-text-input/base-text-input.component.scss",
    "./search-form.component.scss",
  ],
})
export class SearchFormComponent extends BaseTextInputComponent {
  private destroyRef = inject(DestroyRef)
  private manuallyChanged$ = new Subject<string>()

  override ngOnInit() {
    this.manuallyChanged$
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe((value) => {
        this.valueChange.emit(value)
      })
  }

  override onValueChange(value: string) {
    this.manuallyChanged$.next(value)
  }
}
