import { animate, group, query, style, transition, trigger } from "@angular/animations"

const forward = [
  query(":enter, :leave", style({ position: "fixed", width: "100%" }), {
    optional: true,
  }),
  group([
    query(
      ":enter",
      [
        style({ transform: "translateX(100%)" }),
        animate("0.2s ease", style({ transform: "translateX(0%)" })),
      ],
      { optional: true },
    ),
    query(
      ":leave",
      [
        style({ transform: "translateX(0%)" }),
        animate("0.2s ease", style({ transform: "translateX(-100%)" })),
      ],
      { optional: true },
    ),
  ]),
]

const backward = [
  query(":enter, :leave", style({ position: "fixed", width: "100%" }), {
    optional: true,
  }),
  group([
    query(
      ":enter",
      [
        style({ transform: "translateX(-100%)" }),
        animate("0.2s ease", style({ transform: "translateX(0%)" })),
      ],
      { optional: true },
    ),
    query(
      ":leave",
      [
        style({ transform: "translateX(0%)" }),
        animate("0.2s ease", style({ transform: "translateX(100%)" })),
      ],
      { optional: true },
    ),
  ]),
]

export const animations = [
  trigger("routeAnimations", [
    transition("no => *", []),
    transition("backward => *", backward),
    transition("login => money", forward),
    transition("account => login", backward),
    transition("money => money-new", forward),
    transition("money-new => money", backward),
    transition("money => money-edit", forward),
    transition("money-edit => money", backward),
    transition("money => money-archive", forward),
    transition("money-archive => money", backward),
    transition("money-archive => money-archive-detail", forward),
    transition("money-archive-detail => money-archive", backward),
    transition("money => money-analysis", forward),
    transition("money-analysis => money", backward),
    transition("game => game-detail", forward),
    transition("game => game-new", forward),
    transition("game-detail => game-edit", forward),
    transition("game-detail => game", backward),
    transition("game-new => game", backward),
    transition("game-edit => game-detail", backward),
    transition("game-edit => game", backward),
  ]),
]
