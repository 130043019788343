import { Injectable } from "@angular/core"
import { Store, StoreConfig } from "@datorama/akita"

import { PaymentDm } from "../../defs/types"

export interface PaymentsState {
  payments: Array<PaymentDm>
  initialized: boolean
  isAnimationCompletedDutchAmountComponent: boolean
  isAnimationCompletedAmountDetailsComponent: boolean
}

const createInitialState = (): PaymentsState => {
  return {
    payments: [],
    initialized: false,
    isAnimationCompletedDutchAmountComponent: false,
    isAnimationCompletedAmountDetailsComponent: false,
  }
}

@Injectable({
  providedIn: "root",
})
@StoreConfig({ name: "Payments" })
export class PaymentsStore extends Store<PaymentsState> {
  constructor() {
    super(createInitialState())
  }
}
