import { Amplify } from "aws-amplify"

import { environment } from "../environments/environment"

export const initCognitoConfigs = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: environment.USERPOOL_ID,
        userPoolClientId: environment.USERPOOL_WEBCLIENT_ID,
      },
    },
  })
}
