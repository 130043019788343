import { DialogRef } from "@angular/cdk/dialog"
import { Component, OnInit, TemplateRef, ViewChild, inject } from "@angular/core"
import { NavigationStart, Router, RouterModule } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { filter } from "rxjs"

import { HalfModalComponent } from "../../../shared/components/modal/half-modal/half-modal.component"
import { ModalService } from "../../../shared/services/modal.service"
import { PaymentsHalfModalComponent } from "../payments-half-modal/payments-half-modal.component"

@Component({
  selector: "c-other-feature-links",
  standalone: true,
  imports: [RouterModule, FontAwesomeModule, PaymentsHalfModalComponent],
  templateUrl: "./other-feature-links.component.html",
  styleUrl: "./other-feature-links.component.scss",
})
export class OtherFeatureLinksComponent implements OnInit {
  private modalService = inject(ModalService)
  private router = inject(Router)

  @ViewChild("paymentsHalfModal", { read: TemplateRef })
  modal!: TemplateRef<HTMLDivElement>

  dialogRef!: DialogRef

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        if (this.dialogRef) {
          this.dialogRef.close()
        }
      })
  }

  openUnsettledPaymentsHalfModal() {
    this.dialogRef = this.modalService.open(
      HalfModalComponent,
      {
        templateType: "free",
        context: {
          content: this.modal,
          height: 83,
        },
      },
      { autoFocus: "dialog" },
    )
  }

  unimplemented() {
    alert("まだつくってないので何かご希望ありましたらぜひ！")
  }
}
