import { Amplify } from '../Amplify.mjs';
import { fetchAuthSession as fetchAuthSession$1 } from './internal/fetchAuthSession.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const fetchAuthSession = (options) => {
    return fetchAuthSession$1(Amplify, options);
};

export { fetchAuthSession };

