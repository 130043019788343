<div class="root">
  <div class="_title">現在の未精算項目</div>
  <div class="unsettled_table">
    <c-payment-table />
  </div>
  <div class="footer">
    <c-base-button
      class="expand radius settle"
      [height]="'53px'"
      [spinnerColor]="'#222222'"
      [isSubmitting]="isSubmitting$ | async"
      (click)="settle()"
      >精算する</c-base-button
    >
    <div class="note">※各行を横にスクロールすると編集ボタンが出てきます。</div>
  </div>
</div>
