import { Injectable, inject } from "@angular/core"
import { map } from "rxjs"

import { ApiError } from "../../../global/defs/errors"
import { ApiService } from "../../../global/services/api.service"
import { ResPaymentArchiveDto, transformPaymentArchiveResToDm } from "../../defs/types"
import { PaymentsArchiveState, PaymentsArchiveStore } from "./payments-archive.store"

@Injectable({
  providedIn: "root",
})
export class PaymentsArchiveService {
  private store = inject(PaymentsArchiveStore)
  private apiService = inject(ApiService)

  init() {
    this.fetchAndStore({ useLoading: true })
    this.store.update({ initialized: true })
  }

  update<K extends keyof PaymentsArchiveState>(key: K, value: PaymentsArchiveState[K]) {
    this.store.update({ [key]: value })
  }

  fetchAndStore(opts: { useLoading?: boolean } = {}) {
    if (opts.useLoading) {
      this.store.setLoading(true)
    }

    this.apiService
      .req<Array<Array<ResPaymentArchiveDto>>>("GET", "/payments/archive")
      .pipe(
        map((paymentsArchive) =>
          paymentsArchive.map((payments) => transformPaymentArchiveResToDm(payments)),
        ),
      )
      .subscribe({
        next: (paymentsArchive) => {
          this.store.update({ paymentsArchive })
          this.store.setLoading(false)
        },
        error: () => {
          throw new ApiError("GET /payments/archive")
        },
      })
  }
}
