<div class="root" (blur)="onBlur()">
  @for (item of data; track item.value) {
    <div class="radio">
      <input
        type="radio"
        [name]="name"
        [id]="item.id"
        [value]="item.value"
        [checked]="item.value === _value"
        [disabled]="disabled"
        [(ngModel)]="_value"
        (ngModelChange)="onChange($event)"
      />
      <label [for]="item.id">{{ item.label }}</label>
    </div>
  }
</div>
