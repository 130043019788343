<div class="root">
  <c-page-title>{{
    mode === "new" ? "新しい支払いの入力" : "支払いの編集"
  }}</c-page-title>
  <div class="page_wrap">
    <form [formGroup]="form">
      <div class="tab_wrap">
        <c-two-choices-tab
          formControlName="adjust"
          [data]="[
            { label: '割り勘', value: tabTypes[0]! },
            { label: '調整', value: tabTypes[1]! }
          ]"
          (ngModelChange)="onTabTypeChange($event)"
        />
      </div>
      <div class="direction_wrap">
        <c-base-radio-button-group
          formControlName="user_name"
          [data]="paymentDirections[currentTabIndex]!"
        />
      </div>
      <div class="amount_wrap">
        <c-base-form
          formControlName="amount"
          [id]="'amount-form'"
          [label]="amountLabel[currentTabIndex]"
          [type]="'number'"
          [placeholder]="'300'"
          (focusEvent)="onAmountFocus()"
          (blurEvent)="onAmountBlur()"
        />
        <div
          class="comma_value"
          [class.disabled]="isSubmitting$ | async"
          [ngClass]="{
            show: this.currentFocus === 'display',
            hide: this.currentFocus === 'form'
          }"
          [innerHTML]="amountForComma | numberComma"
          (click)="focusAmount()"
        >
        </div>
        @if (mode === "new") {
          <div class="desc"
            >カンマもしくは改行で区切って複数入力すると自動で合算させて金額フォームに入力できます。</div
          >
          <c-base-textarea
            formControlName="calculator"
            [height]="'107px'"
            (valueChange)="onAmountCalculatorChange($event)"
          />
        }
      </div>
      <div class="purpose_wrap">
        <c-base-form
          formControlName="purpose"
          [label]="'摘要'"
          [placeholder]="'マックスバリュでお買い物'"
        />
      </div>
      <div class="options_wrap">
        <c-base-checkbox
          formControlName="notification"
          [label]="
            mode === 'new'
              ? '支払い登録時に相手に通知する'
              : '支払い更新時に相手に通知する'
          "
        />
      </div>
      <div class="main_button_wrap">
        <c-base-button
          class="primary expand radius"
          formControlName="button"
          [height]="'43px'"
          [isSubmitting]="isSubmitting$ | async"
          (click)="mode === 'new' ? postPayment() : putPayment()"
        >
          {{ mode === "new" ? "支払いを登録する" : "支払いを更新する" }}
        </c-base-button>
      </div>
    </form>
    @if (mode === "edit") {
      <div class="delete">
        <c-base-button [type]="'text'" (click)="deletePayment()">
          <fa-icon icon="trash-can"></fa-icon>
          この支払いを削除する
        </c-base-button>
      </div>
    }
  </div>
</div>
