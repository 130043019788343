import { Injectable } from "@angular/core"
import { Store, StoreConfig } from "@datorama/akita"

import { UserName } from "../../defs/types"

export interface UserState {
  name: UserName
  email: string
  vapidKey: string
  isLoggedIn: boolean
}

const createInitialState = (): UserState => {
  return {
    name: "圭",
    email: "",
    vapidKey: "",
    isLoggedIn: false,
  }
}

@Injectable({
  providedIn: "root",
})
@StoreConfig({ name: "User", resettable: true })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState())
  }
}
