<div class="root">
  <c-page-title>これまでの統計データ</c-page-title>
  <div class="body">
    <div class="chart_wrap">
      <div class="title">
        月ごとの支払い額
        <div class="range">
          <c-native-select-box
            [items]="rangeItems"
            (valueChange)="onRangeChange($event)"
          />
        </div>
      </div>
      <div class="chart" style="height: 227px">
        @if (!(isLoading$ | async)) {
        <c-payment-amount-area-chart [data]="paymentAmountData" />
        <div class="legend payment_amount">
          <div class="row">
            <div class="circle" style="background-color: #f9d3b3"></div>
            <span>圭</span>
          </div>
          <div class="row">
            <div class="circle" style="background-color: #f4b3ad"></div>
            <span>万純</span>
          </div>
        </div>
        }
      </div>
    </div>
    <div class="chart_wrap">
      <div class="title">支払い件数の比率</div>
      <div class="chart" style="height: 238.44px">
        @if (!(isLoading$ | async)) {
        <c-payment-count-pie-chart [data]="paymentCountData" [size]="234" />
        <div class="legend payment_count">
          <div class="row">
            <div class="circle" style="background-color: #f9d3b3"></div>
            <span>圭</span>
          </div>
          <div class="row">
            <div class="circle" style="background-color: #f4b3ad"></div>
            <span>万純</span>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>
