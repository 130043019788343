import { DialogRef } from "@angular/cdk/dialog"
import { CommonModule } from "@angular/common"
import { Component, Input, OnInit, TemplateRef, ViewChild, inject } from "@angular/core"
import { NavigationStart, Router } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { Observable, filter } from "rxjs"

import { UserQuery } from "../../../global/states/user"
import { BaseButtonComponent } from "../../../shared/components/button/base-button/base-button.component"
import { HalfModalComponent } from "../../../shared/components/modal/half-modal/half-modal.component"
import { RepeatPipe } from "../../../shared/pipes/repeat.pipe"
import { ModalService } from "../../../shared/services/modal.service"
import { PaymentDm } from "../../defs/types"
import { NumberCommaPipe } from "../../pipes/number-comma.pipe"
import { PaymentTableComponent } from "../payment-table/payment-table.component"
import { PaymentsHalfModalComponent } from "../payments-half-modal/payments-half-modal.component"

@Component({
  selector: "c-latest-payments",
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    NumberCommaPipe,
    RepeatPipe,
    BaseButtonComponent,
    PaymentTableComponent,
    PaymentsHalfModalComponent,
  ],
  templateUrl: "./latest-payments.component.html",
  styleUrl: "./latest-payments.component.scss",
})
export class LatestPaymentsComponent implements OnInit {
  @Input({ required: true }) payments$!: Observable<Array<PaymentDm>>
  @Input({ required: true }) isLoading$!: Observable<boolean>

  @ViewChild("paymentsHalfModal", { read: TemplateRef })
  modal!: TemplateRef<HTMLDivElement>

  private userQuery = inject(UserQuery)
  private modalService = inject(ModalService)
  private router = inject(Router)

  dialogRef!: DialogRef
  myName = this.userQuery.name

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        if (this.dialogRef) {
          this.dialogRef.close()
        }
      })
  }

  openUnsettledPaymentsHalfModal() {
    this.dialogRef = this.modalService.open(
      HalfModalComponent,
      {
        templateType: "free",
        context: {
          content: this.modal,
          height: 83,
        },
      },
      { autoFocus: "dialog" },
    )
  }
}
