import { CommonModule } from "@angular/common"
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from "@angular/core"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { gsap } from "gsap"
import { Observable, combineLatest } from "rxjs"

import { UserName } from "../../../global/defs/types"
import { UserQuery } from "../../../global/states/user"
import { PaymentDm } from "../../defs/types"
import { NumberCommaPipe } from "../../pipes/number-comma.pipe"
import { DutchAmountCalculator } from "../../services/dutch-amount-calculator.service"
import { PaymentsQuery, PaymentsService } from "../../states/payments"

@Component({
  selector: "c-dutch-amount",
  standalone: true,
  imports: [CommonModule, NumberCommaPipe],
  templateUrl: "./dutch-amount.component.html",
  styleUrl: "./dutch-amount.component.scss",
})
export class DutchAmountComponent implements OnInit {
  @Input({ required: true }) payments$!: Observable<Array<PaymentDm>>
  @Input({ required: true }) isLoading$!: Observable<boolean>

  private userQuery = inject(UserQuery)
  private paymentsQuery = inject(PaymentsQuery)
  private paymentsService = inject(PaymentsService)
  private dutchAmountCalculator = inject(DutchAmountCalculator)
  private cd = inject(ChangeDetectorRef)
  private destroyRef = inject(DestroyRef)

  amount = 0
  whoToPay!: UserName | null
  isPositive!: boolean
  abs = (value: number) => Math.abs(value)
  round = (value: number) => Math.round(value)

  ngOnInit() {
    // 現在、WebSocket で更新された場合は数値アニメーションは動いてない
    combineLatest([this.payments$, this.isLoading$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([payments, isLoading]) => {
        if (!isLoading) {
          if (!this.paymentsQuery.animationedDutchAmount) {
            const self = this
            gsap.to(this, {
              duration: 0.77,
              amount: this.dutchAmountCalculator.calc(payments),
              ease: "Power1.easeOut",
              onUpdate() {
                self.isPositive = 0 <= Number(self.amount)
                self.cd.detectChanges()
              },
              onComplete: () => {
                this.whoToPay = this.getWhoToPay(self.amount)
                this.cd.detectChanges()
                this.paymentsService.update(
                  "isAnimationCompletedDutchAmountComponent",
                  true,
                )
              },
            })
          } else {
            this.amount = this.dutchAmountCalculator.calc(payments)
            this.isPositive = 0 <= Number(this.amount)
            this.whoToPay = this.getWhoToPay(this.amount)
          }
        }
      })
  }

  private getWhoToPay(amount: number): UserName | null {
    if (amount === 0) {
      return null
    } else {
      return this.isPositive ? this.userQuery.name : this.userQuery.partnerName
    }
  }
}
