import { Component, Input, OnInit, forwardRef } from "@angular/core"
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms"

type Data = { label: string; value: string; id?: string }

@Component({
  selector: "c-base-radio-button-group",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./base-radio-button-group.component.html",
  styleUrl: "./base-radio-button-group.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseRadioButtonGroupComponent),
      multi: true,
    },
  ],
})
export class BaseRadioButtonGroupComponent implements OnInit, ControlValueAccessor {
  @Input({ required: true }) data!: Array<Data>
  @Input() value!: string

  // If you want to use it alone without including it in Reactive Form,
  // you can define normal `EventEmitter`.

  _value!: string
  name = Math.random().toString(36).slice(2, 9)
  disabled = false
  onChange = (_value: string) => {}
  onTouched = () => {}

  ngOnInit() {
    this.data = this.data.map((item) => ({
      id: "id-" + Math.random().toString(36).slice(2, 9),
      ...item,
    }))

    this._value = this.value ?? this.data[0]?.value
  }

  writeValue(value: string) {
    this._value = value
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  onBlur() {
    this.onTouched()
  }
}
