import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from "@angular/core"
import { Chart, ChartConfiguration } from "chart.js"

import { UserName } from "../../../global/defs/types"

export interface StackedAreaData {
  labels: Array<string>
  datasets: Array<{
    label: UserName
    data: Array<number>
  }>
}

@Component({
  selector: "c-payment-amount-area-chart",
  standalone: true,
  imports: [],
  template: `
    <canvas #chart height="180"></canvas>
  `,
})
export class PaymentAmountAreaChartComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input({ required: true }) data!: StackedAreaData

  @ViewChild("chart") chart!: ElementRef<HTMLCanvasElement>

  chartInstance!: Chart

  ngAfterViewInit() {
    this.drawChart(this.data)
  }

  ngOnChanges() {
    if (this.chartInstance) {
      this.drawChart(this.data)
    }
  }

  private drawChart(data: StackedAreaData) {
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }

    const ctx = this.chart.nativeElement.getContext("2d")!
    const height = this.chart.nativeElement.height

    const gradient1 = ctx.createLinearGradient(0, 0, 0, height)
    gradient1.addColorStop(0, "#f9d3b3ca")
    gradient1.addColorStop(1, "#feda8f5a")

    const gradient2 = ctx.createLinearGradient(0, 0, 0, height)
    gradient2.addColorStop(0, "#f4b3adca")
    gradient2.addColorStop(1, "#f2b7d35a")

    const datasets = data.datasets.map((dataset) => ({
      ...dataset,
      backgroundColor: dataset.label === "圭" ? gradient1 : gradient2,
      borderColor: dataset.label === "圭" ? "#cfa684" : "#de8a83",
      fill: true,
    }))

    // const totals = data.datasets[0]!.data.map(
    //   (value, i) => value + data.datasets[1]!.data[i]!,
    // )

    const chartConfig: ChartConfiguration = {
      type: "line",
      data: {
        labels: data.labels,
        datasets: datasets.reverse(), // グラフが積まれる順番
      },
      options: {
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            // max: Math.max(...totals) * 1.13,
            // min: Math.min(...totals),
            // beginAtZero: false,
            stacked: true,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        font: {
          family: "Custom Noto Sans",
        },
      },
    }

    this.chartInstance = new Chart(ctx, chartConfig)
  }

  ngOnDestroy() {
    this.chartInstance.destroy()
  }
}
