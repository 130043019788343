import { Injectable, inject } from "@angular/core"
import { Query } from "@datorama/akita"
import { Observable } from "rxjs"

import { UserName } from "../../defs/types"
import { UserState, UserStore } from "./user.store"

@Injectable({
  providedIn: "root",
})
export class UserQuery extends Query<UserState> {
  protected override store = inject(UserStore)

  name$ = this.select((state) => state.name)
  partnerName$: Observable<UserName> = this.select((state) =>
    state.name === "圭" ? "万純" : "圭",
  )
  email$ = this.select((state) => state.email)
  vapidKey$ = this.select((state) => state.vapidKey)
  isLoggedIn$ = this.select((state) => state.isLoggedIn)

  // biome-ignore lint:
  constructor(store: UserStore) {
    super(store)
  }

  get name(): UserName {
    return this.getValue().name
  }

  get partnerName(): UserName {
    return this.name === "圭" ? "万純" : "圭"
  }

  get email(): string {
    return this.getValue().email
  }

  get vapidKey(): string {
    return this.getValue().vapidKey
  }

  get isLoggedIn(): boolean {
    return this.getValue().isLoggedIn
  }
}
