import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormsModule } from "@angular/forms"

// 別のところで使う用事が出てきたらリアクティブフォームの実装を追加してよいと思っている

export interface Item {
  value: string
  text?: string
  disabled?: boolean
}

@Component({
  selector: "c-native-select-box",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./native-select-box.component.html",
  styleUrl: "./native-select-box.component.scss",
})
export class NativeSelectBoxComponent implements OnInit {
  @Input({ required: true }) items!: Array<Item>
  @Input() value!: string

  @Output() valueChange = new EventEmitter<string>()

  ngOnInit() {
    this.value = this.value ? this.value : this.items[0]!.value
  }

  onValueChange(value: string) {
    this.valueChange.emit(value)
  }
}
