import { CommonModule } from "@angular/common"
import { Component, inject } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthError, type SignInInput, fetchAuthSession, signIn } from "aws-amplify/auth"

import { Subject } from "rxjs"
import { UserName } from "../../features/global/defs/types"
import { UserService } from "../../features/global/states/user"
import { BaseButtonComponent } from "../../features/shared/components/button/base-button/base-button.component"
import { BaseTextInputComponent } from "../../features/shared/components/form/base-text-input/base-text-input.component"
import { ToastService } from "../../features/shared/services/toast.service"

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, BaseTextInputComponent, BaseButtonComponent],
  templateUrl: "./login.page.html",
  styleUrl: "./login.page.scss",
})
export class LoginPageComponent {
  private router = inject(Router)
  private userService = inject(UserService)
  private toastService = inject(ToastService)

  user: SignInInput = { username: "", password: "" }
  isLoggingIn$ = new Subject<boolean>()

  async logIn(user: SignInInput) {
    this.isLoggingIn$.next(true)

    try {
      await signIn(user)
    } catch (err) {
      // このページではトーストが display: none; されていることに留意（起動時の「ログインしてください」対策）

      console.error(err)
      if (err instanceof AuthError && err.name !== "UserAlreadyAuthenticatedException") {
        // this.toastService.error("入力内容を確認してください")
        this.setInvalidStyle()
        this.isLoggingIn$.next(false)
        return
      } else if (
        err instanceof AuthError &&
        err.name === "UserAlreadyAuthenticatedException"
      ) {
        // Nothing to do
        this.isLoggingIn$.next(false)
      } else {
        // this.toastService.error("これは予想外です。連絡ください")
        this.isLoggingIn$.next(false)
        return
      }
    }

    const res = await fetchAuthSession()
    this.userService.setAll({
      name: res.tokens!.idToken!.payload.name as UserName,
      email: res.tokens!.idToken!.payload.email as string,
      vapidKey: "",
      isLoggedIn: true,
    })

    this.router.navigateByUrl("/money")
  }

  onFocus() {
    this.resetInvalidStyle()
  }

  forgot() {
    alert("圭に連絡してね！")
  }

  private setInvalidStyle() {
    document.getElementById("username")!.style.border = "solid 1.9px var(--color-invalid)"
    document.getElementById("password")!.style.border = "solid 1.9px var(--color-invalid)"
  }

  private resetInvalidStyle() {
    document.getElementById("username")!.style.border = ""
    document.getElementById("password")!.style.border = ""
  }
}
