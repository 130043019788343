import { ENVIRONMENT_INITIALIZER, NgZone, inject } from "@angular/core"
import { enableAkitaProdMode, persistState } from "@datorama/akita"
import { DevtoolsOptions, akitaDevtools } from "@datorama/akita"

import { environment } from "../environments/environment"

export const initAkitaConfigs = () => {
  if (environment.IS_PRD) {
    enableAkitaProdMode()
  }
  persistState({
    include: ["AskAgain", "User"],
    storage: localStorage,
  })
  persistState({
    include: ["Payments", "PaymentsArchive", "Games"],
    storage: sessionStorage,
  })
}

export const provideAkitaDevtools = (options: Partial<DevtoolsOptions> = {}) => {
  return {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useFactory() {
      return () => {
        akitaDevtools(inject(NgZone), options)
      }
    },
  }
}
