<button
  #button
  type="button"
  [class]="{
    fill: type === 'fill',
    outline: type === 'outline',
    text: type === 'text'
  }"
  [style]="{
    width: width(),
    height: _height()
  }"
  [disabled]="isSubmitting || disabled"
  (click)="onClick($event)"
>
  @if (isSubmitting) {
    <c-spinner [color]="_spinnerColor" />
  } @else {
    <ng-content />
  }
</button>
