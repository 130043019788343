import { CommonModule } from "@angular/common"
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from "@angular/core"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { gsap } from "gsap"
import { Observable, combineLatest } from "rxjs"

import { UserQuery } from "../../../global/states/user"
import { PaymentArchiveDm, PaymentDm } from "../../defs/types"
import { NumberCommaPipe } from "../../pipes/number-comma.pipe"
import { DutchAmountCalculator } from "../../services/dutch-amount-calculator.service"
import { PaymentsQuery, PaymentsService } from "../../states/payments"

@Component({
  selector: "c-amount-details",
  standalone: true,
  imports: [CommonModule, NumberCommaPipe],
  templateUrl: "./amount-details.component.html",
  styleUrl: "./amount-details.component.scss",
})
export class AmountDetailsComponent implements OnInit {
  @Input({ required: true }) payments$!: Observable<Array<PaymentDm>>
  @Input({ required: true }) paymentsArchive$!: Observable<Array<Array<PaymentArchiveDm>>>
  @Input({ required: true }) isLoading$!: Observable<boolean>

  private userQuery = inject(UserQuery)
  private paymentsQuery = inject(PaymentsQuery)
  private paymentsService = inject(PaymentsService)
  private dutchAmountCalculator = inject(DutchAmountCalculator)
  private cd = inject(ChangeDetectorRef)
  private destroyRef = inject(DestroyRef)

  total = 0
  totalIPaid = 0
  dutchAmount = 0
  totalAmount = 0
  myName = this.userQuery.name
  abs = (value: number) => Math.abs(value)
  round = (value: number) => Math.round(value)

  ngOnInit() {
    combineLatest([this.payments$, this.paymentsArchive$, this.isLoading$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([payments, paymentsArchive, isLoading]) => {
        if (!isLoading) {
          if (!this.paymentsQuery.animationedAmountDetails) {
            gsap.to(this, {
              duration: 0.77,
              total: this.calcTotal(payments),
              totalIPaid: this.calcTotalIPaid(payments),
              dutchAmount: this.dutchAmountCalculator.calc(payments),
              totalAmount: this.calcTotalSettledAmount(paymentsArchive) + this.total,
              ease: "Power1.easeOut",
              onUpdate: () => {
                this.cd.detectChanges()
              },
              onComplete: () => {
                this.cd.detectChanges()
                this.paymentsService.update(
                  "isAnimationCompletedAmountDetailsComponent",
                  true,
                )
              },
            })
          } else {
            this.total = this.calcTotal(payments)
            this.totalIPaid = this.calcTotalIPaid(payments)
            this.dutchAmount = this.dutchAmountCalculator.calc(payments)
            this.totalAmount = this.calcTotalSettledAmount(paymentsArchive) + this.total
          }
        }
      })
  }

  // これはテスト書くつもりなので protected にしている
  protected calcTotal(payments: Array<PaymentDm>): number {
    let result = 0
    for (const payment of payments) {
      result += Math.abs(payment.amount)
    }
    return result
  }

  protected calcTotalIPaid(payments: Array<PaymentDm>): number {
    let result = 0
    for (const payment of payments) {
      if (payment.userName === this.myName) {
        result += Math.abs(payment.amount)
      }
    }
    return result
  }

  protected calcTotalSettledAmount(
    paymentsArchive: Array<Array<PaymentArchiveDm>>,
  ): number {
    let result = 0
    for (const payments of paymentsArchive) {
      for (const payment of payments) {
        result += Math.abs(payment.amount)
      }
    }
    return result
  }
}
