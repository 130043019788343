import { Component } from "@angular/core"

import { GameBasePageComponent } from "../../../features/game/components/game-base-page/game-base-page.component"

@Component({
  standalone: true,
  imports: [GameBasePageComponent],
  templateUrl: "./game-new.page.html",
})
export class GameNewPageComponent {}
