import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: "numberComma",
  standalone: true,
})
export class NumberCommaPipe implements PipeTransform {
  transform(value: string | number): string {
    return value.toLocaleString().replaceAll(",", '<span class="comma">,</span>')
  }
}
