import { Component, OnInit, inject } from "@angular/core"
import { MatTabChangeEvent, MatTabsModule } from "@angular/material/tabs"
import { RouterModule } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { Observable, Subject, combineLatest, map, of, startWith } from "rxjs"

import { GameListComponent } from "../../features/game/components/game-list/game-list.component"
import { GameDm, Sort } from "../../features/game/defs/types"
import { GamesQuery, GamesService } from "../../features/game/states/games"
import {
  Item,
  NativeSelectBoxComponent,
} from "../../features/global/components/native-select-box/native-select-box.component"
import { BaseButtonComponent } from "../../features/shared/components/button/base-button/base-button.component"
import { SearchFormComponent } from "../../features/shared/components/form/search-form/search-form.component"

@Component({
  standalone: true,
  imports: [
    RouterModule,
    MatTabsModule,
    FontAwesomeModule,
    SearchFormComponent,
    NativeSelectBoxComponent,
    GameListComponent,
    BaseButtonComponent,
  ],
  templateUrl: "./game.page.html",
  styleUrl: "./game.page.scss",
})
export class GamePageComponent implements OnInit {
  private gamesQuery = inject(GamesQuery)
  private gamesService = inject(GamesService)

  games$ = this.gamesQuery.games$
  wantGames$!: Observable<Array<GameDm>>
  playedGames$!: Observable<Array<GameDm>>
  isLoading$ = this.gamesQuery.selectLoading()
  sort = this.gamesQuery.sort as string // 双方向バインドのために仕方がない
  sorts: Array<Item> = [
    {
      value: "created",
      text: "新規追加順",
    },
    {
      value: "updated",
      text: "最終更新順",
    },
    {
      value: "played",
      text: "やった順",
      disabled: true,
    },
  ]
  triggerSearch$ = new Subject<string>()
  triggerSort$ = new Subject<Sort>()
  isScrolled = false

  ngOnInit() {
    if (!this.gamesQuery.initialized) {
      this.gamesService.init()
    } else {
      this.gamesService.fetchAndStore({ useLoading: false })
    }

    if (!this.gamesService.hasWebSocketConnection) {
      this.gamesService.connectWebSocket()
    }

    combineLatest([
      this.games$,
      this.triggerSearch$.pipe(startWith("")),
      this.triggerSort$.pipe(startWith(this.gamesQuery.sort)),
    ])
      .pipe(
        map(([games, searchWord, sort]) => {
          return this.sortGames(
            games.filter((game) => {
              const serialized = Object.values(game).join(" ").toLowerCase().normalize()
              return serialized.includes((searchWord ?? "").toLowerCase().normalize())
            }) || [],
            sort as Sort,
          )
        }),
      )
      .subscribe((games) => {
        this.wantGames$ = of(games.filter((game) => !game.played))
        this.playedGames$ = of(games.filter((game) => game.played))
      })
  }

  incrementalSearch(s: string) {
    this.triggerSearch$.next(s)
  }

  onChangeSort(value: string) {
    this.triggerSort$.next(value as Sort)
    this.gamesService.update("sort", value as Sort)
  }

  onChangeTab(event: MatTabChangeEvent) {
    if (event.index === 1) {
      this.sorts[2]!.disabled = false
    } else {
      this.sorts[2]!.disabled = true
      if (this.sort === "played") {
        this.sort = "created"
        this.triggerSort$.next("created")
      }
    }
  }

  deformButton(event: Event) {
    if (103 < (event.target as HTMLDivElement).scrollTop) {
      this.isScrolled = true
    } else {
      this.isScrolled = false
    }
  }

  private sortGames(games: Array<GameDm>, sort: Sort) {
    switch (sort) {
      case "created":
        return games.sort((a, b) => b.createdAt - a.createdAt)
      case "updated":
        return games.sort((a, b) => b.updatedAt - a.updatedAt)
      case "played":
        // a - b にして正しくソートされない原因が不明
        return games.sort((a, b) => b.playedAt - a.playedAt).reverse()
    }
  }
}
