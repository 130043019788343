import { Injectable, inject } from "@angular/core"
import { Subject, map } from "rxjs"
import { io } from "socket.io-client"

import { environment } from "../../../../../environments/environment"
import { ApiError } from "../../../global/defs/errors"
import { ApiService } from "../../../global/services/api.service"
import { GameDm, ResGameDto, transformGameResToDm } from "../../defs/types"
import { GamesState, GamesStore } from "./games.store"

@Injectable({
  providedIn: "root",
})
export class GamesService {
  private store = inject(GamesStore)
  private apiService = inject(ApiService)

  socket = io(environment.WS_BASE + "/games")
  hasWebSocketConnection = false
  recieveNewThumbnail$ = new Subject<void>()

  init() {
    this.fetchAndStore({ useLoading: true })
    this.store.update({ initialized: true })
  }

  update<K extends keyof GamesState>(key: K, value: GamesState[K]) {
    this.store.update({ [key]: value })
  }

  add(game: GameDm) {
    this.store.setLoading(true)
    const current = [...this.store.getValue().games]
    current.unshift(game)
    this.store.update({ games: current })
    this.store.setLoading(false)
  }

  edit(game: GameDm) {
    this.store.setLoading(true)
    const current = [...this.store.getValue().games]
    const newGames = current.map((currentGame) => {
      if (currentGame.gameId === game.gameId) {
        return game
      } else {
        return currentGame
      }
    })
    this.store.update({ games: newGames })
    this.store.setLoading(false)
  }

  remove(gameId: string) {
    const filtered = this.store.getValue().games.filter((game) => game.gameId !== gameId)
    this.store.update({ games: filtered })
  }

  setImageUrl(gameId: string, url: string) {
    const current = [...this.store.getValue().games]
    const newGames = current.map((currentGame) => {
      if (currentGame.gameId === gameId) {
        return {
          ...currentGame,
          image: url,
        }
      } else {
        return currentGame
      }
    })
    this.store.update({ games: newGames })
  }

  fetchAndStore(opts: { useLoading?: boolean } = {}) {
    if (opts.useLoading) {
      this.store.setLoading(true)
    }

    this.apiService
      .req<Array<ResGameDto>>("GET", "/games")
      .pipe(map((games) => games.map((game) => transformGameResToDm(game))))
      .subscribe({
        next: (games) => {
          this.store.update({ games })
          this.store.setLoading(false)
        },
        error: () => {
          throw new ApiError("GET /games")
        },
      })
  }

  connectWebSocket() {
    this.socket.on("connect", () => console.log("WebSocket connected: `/games`"))

    this.socket.on("create", (game) => {
      this.add(transformGameResToDm(game))
    })

    this.socket.on("update", (game) => {
      this.edit(transformGameResToDm(game))
    })

    this.socket.on("delete", (id) => {
      this.remove(id)
    })

    this.socket.on("image", ({ gameId, url }) => {
      this.setImageUrl(gameId, url)
      this.recieveNewThumbnail$.next()
    })

    // これは sessionStorage に保管せずオンメモリにしないといけないのでフィールド定義＆ここで更新
    this.hasWebSocketConnection = true
  }
}
