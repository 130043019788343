import { Injectable, inject } from "@angular/core"
import { Query } from "@datorama/akita"

import { PaymentDm } from "../../defs/types"
import { PaymentsState, PaymentsStore } from "./payments.store"

@Injectable({
  providedIn: "root",
})
export class PaymentsQuery extends Query<PaymentsState> {
  protected override store = inject(PaymentsStore)

  payments$ = this.select((paymentsState) => paymentsState.payments)

  // biome-ignore lint:
  constructor(store: PaymentsStore) {
    super(store)
  }

  get initialized(): boolean {
    return this.getValue().initialized
  }

  get animationedDutchAmount(): boolean {
    return this.getValue().isAnimationCompletedDutchAmountComponent
  }

  get animationedAmountDetails(): boolean {
    return this.getValue().isAnimationCompletedAmountDetailsComponent
  }

  findById(paymentId: string): PaymentDm {
    return this.getValue().payments.find((payment) => payment.paymentId === paymentId)!
  }
}
