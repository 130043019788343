import { Injectable, inject } from "@angular/core"
import { map } from "rxjs"
import { io } from "socket.io-client"

import { environment } from "../../../../../environments/environment"
import { ApiError } from "../../../global/defs/errors"
import { ApiService } from "../../../global/services/api.service"
import { PaymentDm, ResPaymentDto, transformPaymentResToDm } from "../../defs/types"
import { PaymentsState, PaymentsStore } from "./payments.store"

@Injectable({
  providedIn: "root",
})
export class PaymentsService {
  private store = inject(PaymentsStore)
  private apiService = inject(ApiService)

  socket = io(environment.WS_BASE + "/payments")
  hasWebSocketConnection = false

  init() {
    this.fetchAndStore({ useLoading: true })
    this.store.update({ initialized: true })
  }

  update<K extends keyof PaymentsState>(key: K, value: PaymentsState[K]) {
    this.store.update({ [key]: value })
  }

  add(payment: PaymentDm) {
    this.store.setLoading(true)
    const current = [...this.store.getValue().payments]
    current.unshift(payment)
    this.store.update({ payments: current })
    this.store.setLoading(false)
  }

  edit(payment: PaymentDm) {
    this.store.setLoading(true)
    const current = [...this.store.getValue().payments]
    const newPayments = current.map((currentPayment) => {
      if (currentPayment.paymentId === payment.paymentId) {
        return payment
      } else {
        return currentPayment
      }
    })
    this.store.update({ payments: newPayments })
    this.store.setLoading(false)
  }

  remove(paymentId: string) {
    const filtered = this.store
      .getValue()
      .payments.filter((payment) => payment.paymentId !== paymentId)
    this.store.update({ payments: filtered })
  }

  fetchAndStore(opts: { useLoading?: boolean } = {}) {
    if (opts.useLoading) {
      this.store.setLoading(true)
    }

    this.apiService
      .req<Array<ResPaymentDto>>("GET", "/payments")
      .pipe(
        map((payments) => payments.map((payment) => transformPaymentResToDm(payment))),
      )
      .subscribe({
        next: (payments) => {
          this.store.update({ payments })
          this.store.setLoading(false)
        },
        error: () => {
          throw new ApiError("GET /payments")
        },
      })
  }

  connectWebSocket() {
    this.socket.on("connect", () => console.log("WebSocket connected: `/payments`"))

    this.socket.on("create", (payment) => {
      this.add(transformPaymentResToDm(payment))
    })

    this.socket.on("update", (payment) => {
      this.edit(transformPaymentResToDm(payment))
    })

    this.socket.on("delete", (id) => {
      this.remove(id)
    })

    // これは sessionStorage に保管せずオンメモリにしないといけないのでフィールド定義＆ここで更新
    this.hasWebSocketConnection = true
  }
}
