import { Routes } from "@angular/router"

import { loginGuard } from "./features/global/guards/login.guard"
import { AccountPageComponent } from "./pages/account/account.page"
import { GameDetailPageComponent } from "./pages/game/game-detail/game-detail.page"
import { GameEditPageComponent } from "./pages/game/game-edit/game-edit.page"
import { GameNewPageComponent } from "./pages/game/game-new/game-new.page"
import { GamePageComponent } from "./pages/game/game.page"
import { LoginPageComponent } from "./pages/login/login.page"
import { MoneyAnalysisPageComponent } from "./pages/money/money-analysis/money-analysis.page"
import { MoneyArchiveDetailPageComponent } from "./pages/money/money-archive/money-archive-detail/money-archive-detail.page"
import { MoneyArchivePageComponent } from "./pages/money/money-archive/money-archive.page"
import { MoneyEditPageComponent } from "./pages/money/money-edit/money-edit.page"
import { MoneyNewPageComponent } from "./pages/money/money-new/money-new.page"
import { MoneyPageComponent } from "./pages/money/money.page"
import { UnknownPageComponent } from "./pages/unknown/unknown.page"

export const routes: Routes = [
  {
    path: "",
    redirectTo: "money",
    pathMatch: "full",
  },

  // /login
  {
    path: "login",
    component: LoginPageComponent,
    title: "ログイン | MK03",
    data: { animation: "login" },
  },

  // /money
  {
    path: "money",
    component: MoneyPageComponent,
    title: "お金の管理 | MK03",
    canActivate: [loginGuard],
    data: { animation: "money" },
  },
  {
    path: "money/new",
    component: MoneyNewPageComponent,
    title: "新しい支払い | MK03",
    canActivate: [loginGuard],
    data: { animation: "money-new" },
  },
  {
    path: "money/archive",
    component: MoneyArchivePageComponent,
    title: "精算履歴 | MK03",
    canActivate: [loginGuard],
    data: { animation: "money-archive" },
  },
  {
    path: "money/archive/:id",
    component: MoneyArchiveDetailPageComponent,
    title: "精算履歴の詳細 | MK03",
    canActivate: [loginGuard],
    data: { animation: "money-archive-detail" },
  },
  {
    path: "money/analysis",
    component: MoneyAnalysisPageComponent,
    title: "統計 | MK03",
    canActivate: [loginGuard],
    data: { animation: "money-analysis" },
  },
  {
    path: "money/:id/edit",
    component: MoneyEditPageComponent,
    title: "支払いの編集 | MK03",
    canActivate: [loginGuard],
    data: { animation: "money-edit" },
  },

  // /game
  {
    path: "game",
    component: GamePageComponent,
    title: "やりたいゲーム | MK03",
    canActivate: [loginGuard],
    data: { animation: "game" },
  },
  {
    path: "game/new",
    component: GameNewPageComponent,
    title: "やりたいゲームの追加 | MK03",
    canActivate: [loginGuard],
    data: { animation: "game-new" },
  },
  {
    path: "game/:id",
    component: GameDetailPageComponent,
    title: "ゲームの詳細 | MK03",
    canActivate: [loginGuard],
    data: { animation: "game-detail" },
  },
  {
    path: "game/:id/edit",
    component: GameEditPageComponent,
    title: "ゲームの編集 | MK03",
    canActivate: [loginGuard],
    data: { animation: "game-edit" },
  },

  // /unknown
  {
    path: "unknown",
    component: UnknownPageComponent,
    title: "未定！ | MK03",
    canActivate: [loginGuard],
    data: { animation: "unknown" },
  },

  // /account
  {
    path: "account",
    component: AccountPageComponent,
    title: "アカウント | MK03",
    canActivate: [loginGuard],
    data: { animation: "account" },
  },
]
