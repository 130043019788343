import { Injectable } from "@angular/core"
import { Store, StoreConfig } from "@datorama/akita"

import { PaymentArchiveDm } from "../../defs/types"

export interface PaymentsArchiveState {
  paymentsArchive: Array<Array<PaymentArchiveDm>>
  initialized: boolean
}

const createInitialState = (): PaymentsArchiveState => {
  return {
    paymentsArchive: [],
    initialized: false,
  }
}

@Injectable({
  providedIn: "root",
})
@StoreConfig({ name: "PaymentsArchive" })
export class PaymentsArchiveStore extends Store<PaymentsArchiveState> {
  constructor() {
    super(createInitialState())
  }
}
