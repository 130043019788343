import { CommonModule } from "@angular/common"
import { ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from "@angular/core"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { ActivatedRoute, Router, RouterModule } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { Subject, combineLatest, startWith, take } from "rxjs"

import { GameDm } from "../../../features/game/defs/types"
import { GamesQuery, GamesService } from "../../../features/game/states/games"
import { PageTitleComponent } from "../../../features/global/components/page-title/page-title.component"
import { ApiService } from "../../../features/global/services/api.service"
import { BaseButtonComponent } from "../../../features/shared/components/button/base-button/base-button.component"
import { SpinnerComponent } from "../../../features/shared/components/loading/spinner/spinner.component"

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    PageTitleComponent,
    BaseButtonComponent,
    SpinnerComponent,
  ],
  templateUrl: "./game-detail.page.html",
  styleUrl: "./game-detail.page.scss",
})
export class GameDetailPageComponent implements OnInit {
  private route = inject(ActivatedRoute)
  private router = inject(Router)
  private gamesQuery = inject(GamesQuery)
  private gamesService = inject(GamesService)
  private apiService = inject(ApiService)
  private destroyRef = inject(DestroyRef)
  private cd = inject(ChangeDetectorRef)

  game!: GameDm
  isPlayeding$ = new Subject<boolean>()

  ngOnInit() {
    combineLatest([
      this.route.params,
      this.gamesService.recieveNewThumbnail$.pipe(startWith(undefined)),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([params, _]) => {
        this.game = this.gamesQuery.findById(params.id)
        this.cd.detectChanges()
      })
  }

  played() {
    if (confirm("このゲームはもう「やった」ことにしますか？")) {
      this.isPlayeding$.next(true)

      this.apiService
        .req("PUT", `/games/${this.game.gameId}/played`)
        .pipe(take(1))
        .subscribe({
          next: () => {
            // 「やった！」のセット後はもう普通にフルリロードさせちゃう
            this.gamesService.update("initialized", false)

            this.router.navigateByUrl("/game")
          },
          error: () => {
            this.isPlayeding$.next(false)
          },
        })
    }
  }
}
