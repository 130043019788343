import { CommonModule } from "@angular/common"
import { Component, Input, OnInit, forwardRef } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"

type Data = { label: string; value: string }

@Component({
  selector: "c-two-choices-tab",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./two-choices-tab.component.html",
  styleUrl: "./two-choices-tab.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TwoChoicesTabComponent),
      multi: true,
    },
  ],
})
export class TwoChoicesTabComponent implements OnInit, ControlValueAccessor {
  @Input({ required: true }) data!: [Data, Data]
  @Input() value!: string

  _value!: string
  disabled = false
  onChange = (_value: string) => {}
  onTouched = () => {}

  ngOnInit() {
    this._value = this.value ?? this.data[0]!.value
  }

  writeValue(value: string) {
    if (!this.disabled) {
      this.bindIndexToValue(value)
    }
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  onClick(value: string) {
    if (!this.disabled) {
      this.bindIndexToValue(value)
      this.onChange(value)
    }
  }

  onBlur() {
    this.onTouched()
  }

  private bindIndexToValue(value: string) {
    this.data.map((item, index) => {
      if (item.value === value) {
        this._value = this.data[index]!.value
      }
    })
  }
}
