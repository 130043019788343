<div class="root">
  <div class="dutch_amount_wrap">
    <c-dutch-amount [payments$]="payments$" [isLoading$]="isLoading$" />
  </div>
  <c-amount-details
    [payments$]="payments$"
    [paymentsArchive$]="paymentsArchive$"
    [isLoading$]="isLoading$"
  />
  <c-latest-payments [payments$]="payments$" [isLoading$]="isLoading$" />
  <c-other-feature-links />
  <div class="main_button_wrap">
    <c-base-button
      class="expand radius gradient"
      [height]="'49px'"
      routerLink="/money/new"
      >新しい支払い
    </c-base-button>
  </div>
</div>
