<div class="root">
  <c-page-title>精算履歴</c-page-title>
  <div class="cards">
    @for (payments of paymentsArchive$ | async; track $index) {
    <div class="card" [routerLink]="'/money/archive/' + payments[0]!.archiveId">
      <div class="body">
        <div class="rows">
          <div class="row">
            <div class="label">二人の総支払い額</div>
            <div class="value">
              <span [innerHTML]="calcTotalAmount(payments) | numberComma"></span>
              <span class="unit">円</span>
            </div>
          </div>
          <div class="row">
            <div class="label">件数</div>
            <div class="value">
              <span>{{ payments.length }}</span>
              <span class="unit">件</span>
            </div>
          </div>
          <div class="row">
            <div class="label">精算日時</div>
            <div class="value">
              {{ payments[0]!.settledAt | date: "yyyy/MM/dd HH:mm" }}
            </div>
          </div>
        </div>
      </div>
      <div class="link">
        <fa-icon icon="angle-right" />
      </div>
    </div>
    }
  </div>
</div>
