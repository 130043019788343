import { CommonModule } from "@angular/common"
import { Component, Input, OnInit, inject } from "@angular/core"
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { Subject, take } from "rxjs"

import { GameDm, ReqGameDto } from "../../../../features/game/defs/types"
import { PageTitleComponent } from "../../../../features/global/components/page-title/page-title.component"
import { ApiService } from "../../../../features/global/services/api.service"
import { UserQuery } from "../../../../features/global/states/user"
import { BaseTextInputComponent } from "../../../../features/shared/components/form/base-text-input/base-text-input.component"
import { BaseTextareaComponent } from "../../../../features/shared/components/form/base-textarea/base-textarea.component"
import { ToastService } from "../../../../features/shared/services/toast.service"
import { BaseButtonComponent } from "../../../shared/components/button/base-button/base-button.component"
import { CheckLabelComponent } from "../../../shared/components/form/check-label/check-label.component"
import { GamesQuery } from "../../states/games"
import { imageAnimation } from "./animations"

@Component({
  selector: "c-game-base-page",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    PageTitleComponent,
    BaseButtonComponent,
    BaseTextInputComponent,
    BaseTextareaComponent,
    CheckLabelComponent,
  ],
  templateUrl: "./game-base-page.component.html",
  styleUrl: "./game-base-page.component.scss",
  animations: [imageAnimation],
})
export class GameBasePageComponent implements OnInit {
  @Input({ required: true }) mode!: "new" | "edit"
  @Input() gameToEdit!: GameDm

  private userQuery = inject(UserQuery)
  private gamesQuerty = inject(GamesQuery)
  private formBuilder = inject(FormBuilder)
  private apiService = inject(ApiService)
  private toastService = inject(ToastService)
  private router = inject(Router)

  userName = this.userQuery.name
  randomTitle = this.gamesQuerty.randomTitle
  form = this.formBuilder.nonNullable.group({
    title: ["", Validators.required],
    image: [""],
    memo: [""],
    url: [""],
    created_by: [this.userName, Validators.required],
    button: null,
  })
  isSubmitting$ = new Subject<boolean>()
  isGenerating$ = new Subject<boolean>()
  useThumbnail = false
  isOpenSubMenu = false

  ngOnInit() {
    if (this.mode === "edit") {
      this.form.patchValue({
        title: this.gameToEdit.title,
        image: this.gameToEdit.image,
        memo: this.gameToEdit.memo,
        url: this.gameToEdit.url,
      })
    }
  }

  openSubMenu(event: Event) {
    event.stopPropagation()
    this.isOpenSubMenu = true
  }

  generateThumbnail() {
    if (this.form.value.title === "") {
      this.toastService.error("ゲームのタイトルを入力してね")
      return
    }

    this.isGenerating$.next(true)

    this.apiService
      .req("POST", "/games/thumbnails", {
        body: this.form.value.title,
      })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.form.patchValue({ image: res.url })
          this.isGenerating$.next(false)
        },
        error: () => {
          this.isGenerating$.next(false)
        },
      })
  }

  postGame() {
    this.form.updateValueAndValidity()
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => this.form.get(key)?.markAsDirty())
      this.toastService.error("赤いところを確認してね")
      return
    }

    this.form.disable()
    this.isSubmitting$.next(true)

    const postData: ReqGameDto = {
      title: this.form.value.title!,
      image:
        this.useThumbnail && this.form.value.image ? this.form.value.image : undefined,
      memo: this.form.value.memo,
      url: this.normalizeUrl(this.form.value.url),
      created_by: this.userName,
    }

    this.apiService
      .req("POST", "/games", { body: postData })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigateByUrl("/game")
        },
        error: () => {
          this.form.enable()
          this.isSubmitting$.next(false)
        },
      })
  }

  putGame() {
    this.form.updateValueAndValidity()
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => this.form.get(key)?.markAsDirty())
      this.toastService.error("赤いところを確認してね")
      return
    }

    this.form.disable()
    this.isSubmitting$.next(true)

    const putData: ReqGameDto = {
      title: this.form.value.title!,
      image:
        this.useThumbnail && this.form.value.image ? this.form.value.image : undefined,
      memo: this.form.value.memo,
      url: this.normalizeUrl(this.form.value.url),
      created_by: this.userName,
    }

    this.apiService
      .req("PUT", `/games/${this.gameToEdit.gameId}`, {
        body: putData,
      })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigateByUrl("/game")
        },
        error: () => {
          this.form.enable()
          this.isSubmitting$.next(false)
        },
      })
  }

  deleteGame() {
    if (confirm("本当にこのゲームを削除してもいいですか？")) {
      this.form.disable()
      this.isSubmitting$.next(true)

      this.apiService
        .req("DELETE", `/games/${this.gameToEdit.gameId}`)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.router.navigateByUrl("/game")
          },
          error: () => {
            this.form.enable()
            this.isSubmitting$.next(false)
          },
        })
    }
  }

  private normalizeUrl(url: string | undefined): string | undefined {
    if (!url) {
      return undefined
    }
    let result = url
    if (!url.startsWith("http")) {
      result = "http://" + url
    }
    return result
  }
}
