import { Component, OnInit, inject } from "@angular/core"
import { ActivatedRoute } from "@angular/router"

import { MoneyBasePageComponent } from "../../../features/money/components/money-base-page/money-base-page.component"
import { PaymentDm } from "../../../features/money/defs/types"
import { PaymentsQuery } from "../../../features/money/states/payments"

@Component({
  standalone: true,
  imports: [MoneyBasePageComponent],
  templateUrl: "./money-edit.page.html",
})
export class MoneyEditPageComponent implements OnInit {
  private route = inject(ActivatedRoute)
  private paymentsQuery = inject(PaymentsQuery)

  paymentToEdit!: PaymentDm

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.paymentToEdit = this.paymentsQuery.findById(params.id)
    })
  }
}
