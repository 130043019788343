import { animate, state, style, transition, trigger } from "@angular/animations"
import { CdkDragEnd, CdkDragMove, DragDropModule } from "@angular/cdk/drag-drop"
import { CommonModule } from "@angular/common"
import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"

import { BaseButtonComponent } from "../../button/base-button/base-button.component"
import { ModalBase } from "../modal-base"

@Component({
  selector: "c-half-modal",
  standalone: true,
  imports: [CommonModule, DragDropModule, BaseButtonComponent, FontAwesomeModule],
  templateUrl: "./half-modal.component.html",
  styleUrl: "./half-modal.component.scss",
  animations: [
    trigger("show", [
      state("hidden", style({ opacity: 0, transform: "translateY(100%)" })),
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(100%)" }),
        animate("0.3s ease"), // original: 0.19s
      ]),
      transition("shown => hidden", animate("0.3s ease")), // original: 0.09s
    ]),
  ],
})
export class HalfModalComponent extends ModalBase implements AfterViewInit {
  @ViewChild("modal") modal!: ElementRef<HTMLDivElement>

  override ngAfterViewInit() {
    super.ngAfterViewInit()

    if (this.halfModalHeight === "auto") {
      let plus = 1
      if (this.platform.IOS) {
        plus += 10
      }
      this.halfModalHeight = `${(
        this.modal.nativeElement.offsetHeight + plus
      ).toString()}px`
    }
    this.modal.nativeElement.style.opacity = "1"

    this.cd.detectChanges()
  }

  onDragMoved(event: CdkDragMove) {
    const { y } = event.distance
    if (200 < y) {
      this.close()
    }
  }

  onDragEnded(event: CdkDragEnd) {
    const { y } = event.distance
    if (y < 200) {
      event.source.reset()
    }
  }
}
