import { CommonModule } from "@angular/common"
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
  forwardRef,
  inject,
  signal,
} from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"

import { SpinnerComponent } from "../../loading/spinner/spinner.component"

@Component({
  selector: "c-base-button",
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: "./base-button.component.html",
  styleUrl: "./base-button.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseButtonComponent),
      multi: true,
    },
  ],
})
export class BaseButtonComponent implements ControlValueAccessor, AfterViewInit {
  /**
   * Styling guide for buttons:
   * - Colors are passed from the parent component will be applied directly
   * - Some predefined classes for common use cases styles can be used like this: `<c-base-button class="radius">`
   */

  @Input() type: "fill" | "outline" | "text" = "fill"
  @Input() height?: string
  @Input() isSubmitting?: boolean | null // null: for the async pipe
  @Input() disabled?: boolean
  @Input() spinnerColor?: string

  @ViewChild("button") button!: ElementRef<HTMLButtonElement>

  protected cd = inject(ChangeDetectorRef)

  width = signal("auto")
  _height = signal("auto")
  _spinnerColor!: string

  ngAfterViewInit() {
    this.width.set(`${(this.button.nativeElement.offsetWidth + 3).toString()}px`)
    this._height.set(
      this.height
        ? this.height
        : `${(this.button.nativeElement.offsetHeight + 1).toString()}px`,
    )

    // Determine spinner color
    this._spinnerColor = this.spinnerColor ?? "currentColor"

    this.cd.detectChanges()
  }

  onClick(e: Event) {
    e.preventDefault()
  }

  // Only `setDisabledState` is needed
  writeValue() {}
  registerOnChange() {}
  registerOnTouched() {}

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }
}
