export const environment = {
  APP_VERSION: require("../../package.json").version,
  IS_DEV: true,
  IS_PRD: false,

  API_BASE: "https://inwxqmmyfp.ap-northeast-1.awsapprunner.com",
  WS_BASE: "wss://inwxqmmyfp.ap-northeast-1.awsapprunner.com",
  USERPOOL_ID: "ap-northeast-1_MFoIffpPt",
  USERPOOL_WEBCLIENT_ID: "4ke9fdf4n47kn2r0ve22ulkg2l",

  FIREBASE: {
    projectId: "kei-masumi-house",
    appId: "1:159734866184:web:ceaef40506c857f8708cbd",
    storageBucket: "kei-masumi-house.appspot.com",
    apiKey: "AIzaSyCZC4xA0o5WyDguINL71os7e-0vhBKcROw",
    authDomain: "kei-masumi-house.firebaseapp.com",
    messagingSenderId: "159734866184",
  },
  FIREBASE_VAPID_KEY:
    "BBTj2FBrMJYMH7pAt8lU2HErO_CICE0w8fnKmNHmf3CsxJk68VY1fQKZngwr38IApXYBBWJq0aAXQGKwylWpXhg",
}
