import { CommonModule } from "@angular/common"
import { Component, DestroyRef, OnInit, inject } from "@angular/core"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { FormsModule } from "@angular/forms"
import dayjs from "dayjs"
import { Subject, combineLatest, map, startWith } from "rxjs"

import {
  Item,
  NativeSelectBoxComponent,
} from "../../../features/global/components/native-select-box/native-select-box.component"
import { PageTitleComponent } from "../../../features/global/components/page-title/page-title.component"
import {
  PaymentAmountAreaChartComponent,
  StackedAreaData,
} from "../../../features/money/components/payment-amount-area-chart/payment-amount-area-chart.component"
import {
  PaymentCountPieChartComponent,
  PieDatum,
} from "../../../features/money/components/payment-count-pie-chart/payment-count-pie-chart.component"
import { PaymentArchiveDm, PaymentDm } from "../../../features/money/defs/types"
import { PaymentsQuery } from "../../../features/money/states/payments"
import { PaymentsArchiveQuery } from "../../../features/money/states/payments-archive"

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    PageTitleComponent,
    NativeSelectBoxComponent,
    PaymentAmountAreaChartComponent,
    PaymentCountPieChartComponent,
  ],
  templateUrl: "./money-analysis.page.html",
  styleUrl: "./money-analysis.page.scss",
})
export class MoneyAnalysisPageComponent implements OnInit {
  private paymentsQuery = inject(PaymentsQuery)
  private paymentsArchiveQuery = inject(PaymentsArchiveQuery)
  private destroyRef = inject(DestroyRef)

  paymentAmountData!: StackedAreaData
  paymentCountData!: [PieDatum, PieDatum]
  isLoading$ = combineLatest([
    this.paymentsQuery.selectLoading(),
    this.paymentsArchiveQuery.selectLoading(),
  ]).pipe(map(([a, b]) => a || b))
  rangeValue = "latest12Months"
  rangeItems!: Array<Item>
  rangeChanged$ = new Subject<void>()

  constructor() {
    const base = [{ value: "latest12Months", text: "過去 12 ヶ月" }]
    const years = this.generateYears(new Date().getFullYear())
    for (const year of years) {
      base.push({ value: year, text: `${year} 年` })
    }
    this.rangeItems = base
  }

  ngOnInit() {
    combineLatest([
      this.paymentsQuery.payments$,
      this.paymentsArchiveQuery.paymentsArchive$,
      this.rangeChanged$.pipe(startWith(undefined)),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([payments, paymentsArchive]) => {
        this.paymentAmountData = this.calcPaymentAmount(payments, paymentsArchive)
        this.paymentCountData = this.calcPaymentCounts(payments, paymentsArchive)
      })
  }

  onRangeChange(value: string) {
    this.rangeValue = value
    this.rangeChanged$.next()
  }

  private calcPaymentAmount(
    payments: Array<PaymentDm>,
    paymentsArchive: Array<Array<PaymentArchiveDm>>,
  ): StackedAreaData {
    // 範囲の選択肢から該当する年月のリストをつくる
    const months: Array<dayjs.Dayjs> = []
    if (this.rangeValue === "latest12Months") {
      let currentNow = dayjs()
      for (let i = 0; i < 12; i++) {
        months.push(currentNow)
        currentNow = currentNow.subtract(1, "month")
      }
      months.reverse()
    } else {
      for (let i = 0; i < 12; i++) {
        months.push(dayjs().set("year", Number(this.rangeValue)).set("month", i))
      }
    }

    // その年月リストにヒットする支払い項目を payment/paymentArchvie それぞれ走査して足していく
    let keis: Array<number> = []
    let masumis: Array<number> = []
    for (const month of months) {
      const monthAmountsKei1 = []
      const monthAmountsMasumi1 = []
      for (const payment of payments) {
        if (
          month.year() === dayjs(payment.timestamp).year() &&
          month.month() === dayjs(payment.timestamp).month()
        ) {
          if (payment.userName === "圭") {
            monthAmountsKei1.push(payment.amount)
          } else {
            monthAmountsMasumi1.push(payment.amount)
          }
        }
      }
      keis.push(monthAmountsKei1.reduce((prev, current) => prev + current, 0))
      masumis.push(monthAmountsMasumi1.reduce((prev, current) => prev + current, 0))

      const monthAmountsKei2 = []
      const monthAmountsMasumi2 = []
      for (const payments of paymentsArchive) {
        for (const payment of payments) {
          if (
            month.year() === dayjs(payment.timestamp).year() &&
            month.month() === dayjs(payment.timestamp).month()
          ) {
            if (payment.userName === "圭") {
              monthAmountsKei2.push(payment.amount)
            } else {
              monthAmountsMasumi2.push(payment.amount)
            }
          }
        }
      }
      keis.push(monthAmountsKei2.reduce((prev, current) => prev + current, 0))
      masumis.push(monthAmountsMasumi2.reduce((prev, current) => prev + current, 0))
    }

    // payments と paymentsArchive とで 12 個 12 個交互に入っているので（データは重複してない）、足し合わせる
    keis = keis
      .map((value, i) => {
        if (i % 2 === 0) {
          return value + keis[i + 1]!
        } else {
          return Number.NaN
        }
      })
      .filter((value) => !Number.isNaN(value))
    masumis = masumis
      .map((value, i) => {
        if (i % 2 === 0) {
          return value + masumis[i + 1]!
        } else {
          return Number.NaN
        }
      })
      .filter((value) => !Number.isNaN(value))

    return {
      labels: months.map((month) => month.format("YY/MM")),
      datasets: [
        {
          label: "圭",
          data: keis,
        },
        {
          label: "万純",
          data: masumis,
        },
      ],
    }
  }

  private calcPaymentCounts(
    payments: Array<PaymentDm>,
    paymentsArchive: Array<Array<PaymentArchiveDm>>,
  ): [PieDatum, PieDatum] {
    const unsettledCounts = payments.reduce<[number, number /* kei, masumi */]>(
      (prev, current) => {
        if (current.userName === "圭") {
          prev[0]++
        } else {
          prev[1]++
        }
        return prev
      },
      [0, 0],
    )

    const settledCounts = paymentsArchive.reduce<[number, number]>(
      (prevArray, currentArray) => {
        const counts = currentArray.reduce<[number, number]>((prev, current) => {
          if (current.userName === "圭") {
            prev[0]++
          } else {
            prev[1]++
          }
          return prev
        }, prevArray)
        return counts
      },
      [0, 0],
    )

    const counts = unsettledCounts.map((num, i) => num + settledCounts[i]!) as [
      number,
      number,
    ]
    return [
      {
        label: "圭",
        value: counts[0],
      },
      {
        label: "万純",
        value: counts[1],
      },
    ]
  }

  private generateYears(thisYear: number): Array<string> {
    const years = []
    for (let year = thisYear; 2023 <= year; year--) {
      years.push(year.toString())
    }
    return years
  }
}
