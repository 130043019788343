import { CommonModule } from "@angular/common"
import { Component, OnDestroy, OnInit, inject } from "@angular/core"
import { Subject, take } from "rxjs"

import { ApiService } from "../../../global/services/api.service"
import { BaseButtonComponent } from "../../../shared/components/button/base-button/base-button.component"
import { PaymentsService } from "../../states/payments"
import { PaymentsArchiveService } from "../../states/payments-archive"
import { PaymentTableComponent } from "../payment-table/payment-table.component"

@Component({
  selector: "c-payments-half-modal",
  standalone: true,
  imports: [CommonModule, PaymentTableComponent, BaseButtonComponent],
  templateUrl: "./payments-half-modal.component.html",
  styleUrl: "./payments-half-modal.component.scss",
})
export class PaymentsHalfModalComponent implements OnInit, OnDestroy {
  private apiService = inject(ApiService)
  private paymentsService = inject(PaymentsService)
  private paymentsArchiveService = inject(PaymentsArchiveService)

  isSubmitting$ = new Subject<boolean>()

  eventListener = (event: Event) => {
    if ((event.target as HTMLElement).classList.contains("cell")) {
      return
    }
    event.preventDefault()
  }

  ngOnInit() {
    document.querySelector("body")!.style.overscrollBehaviorY = "none"
    document.addEventListener("touchmove", this.eventListener, { passive: false })
  }

  settle() {
    if (
      confirm("本当に精算していいですか？（現在の未精算リストが全てリセットされます）")
    ) {
      this.isSubmitting$.next(true)

      this.apiService
        .req("POST", "/settlements")
        .pipe(take(1))
        .subscribe({
          next: () => {
            // フルリロードさせちゃう
            this.paymentsService.update("initialized", false)
            this.paymentsArchiveService.update("initialized", false)

            location.reload()
          },
          error: () => {
            this.isSubmitting$.next(false)
          },
        })
    }
  }

  ngOnDestroy() {
    document.removeEventListener("touchmove", this.eventListener)
    document.querySelector("body")!.style.overscrollBehaviorY = ""
  }
}
