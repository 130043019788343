<div class="root" (click)="isOpenSubMenu = false">
  <div class="title">
    <c-page-title>{{
      mode === "new" ? "やりたいゲームの追加" : "ゲームの編集"
    }}</c-page-title>
    @if (mode === "edit") {
      <div class="sub_menu_wrap">
        <fa-icon id="sub-menu" icon="ellipsis-vertical" (click)="openSubMenu($event)" />
        @if (isOpenSubMenu) {
          <div class="sub_menu">
            <div id="delete" class="item" (click)="deleteGame()">削除する</div>
          </div>
        }
      </div>
    }
  </div>
  <div class="form_wrap">
    <form [formGroup]="form">
      <c-base-form
        class="game_title"
        formControlName="title"
        [label]="'ゲームのタイトル'"
        [placeholder]="randomTitle"
      />
      <div class="thumbnail">
        <div class="buttons">
          <c-base-button
            [isSubmitting]="isGenerating$ | async"
            (click)="generateThumbnail()"
          >
            サムネの生成結果を確認する
          </c-base-button>
          <c-check-label [isOn]="useThumbnail" (change)="useThumbnail = $event">
            これを使う
          </c-check-label>
        </div>
        <div class="desc"
          >いま入力中のタイトルで自動生成されるサムネイルをここで事前に確認できます。「これを使う」をチェックするとその生成結果がそのまま登録されます。</div
        >
        <div class="image_wrap">
          @if (form.value.image === "") {
            <div class="placeholder">
              <fa-icon icon="image" />
            </div>
          } @else {
            <div class="image" @image>
              <img [src]="form.value.image" />
            </div>
          }
        </div>
      </div>
      <div class="memo">
        <c-base-textarea
          formControlName="memo"
          [label]="'説明やメモ'"
          [height]="'100px'"
        />
        <div class="desc">改行に対応しています。</div>
      </div>
      <c-base-form class="url" formControlName="url" [label]="'URL'" />
      <c-base-button
        class="primary expand radius"
        formControlName="button"
        [height]="'43px'"
        [isSubmitting]="isSubmitting$ | async"
        (click)="mode === 'new' ? postGame() : putGame()"
      >
        {{ mode === "new" ? "登録する" : "更新する" }}
      </c-base-button>
    </form>
  </div>
</div>
