import { Injectable, inject } from "@angular/core"
import { Messaging, getToken, onMessage } from "@angular/fire/messaging"
import { take } from "rxjs"

import { environment } from "../../../../environments/environment"
import { registerServiceWorker } from "../../../../plugins/firebase"
import { ToastService } from "../../shared/services/toast.service"
import { UserQuery, UserService } from "../states/user"
import { ApiService } from "./api.service"

@Injectable({
  providedIn: "root",
})
export class RegisterPushNotificationService {
  private userQuery = inject(UserQuery)
  private userService = inject(UserService)
  private apiService = inject(ApiService)
  private messaging = inject(Messaging)
  private toast = inject(ToastService)

  userName = this.userQuery.name
  partnerName = this.userQuery.partnerName

  async register() {
    // ここの await を外すと iOS 実機で通知許可ダイアログが先に出てしまい vapid キーの登録が失敗する
    await this.registerFCM()

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Permission granted")
      }
    })

    // もともとコンポーネントにあったコードをサービスに持ってきたら当然トーストは動かなくなったんだけど、
    // なぜか /money にいてもネイティブ通知が出るように棚ぼたがあったので、そのままにする（もともとそうしたかったし）
    // というわけでこのコードは本来いらないのだけど、忘れたくないので残しておく
    onMessage(this.messaging, (_message) => {
      this.toast.info(`${this.partnerName} さんが新しい支払いを登録しました`)
    })
  }

  private async registerFCM() {
    // 更新がなければ置き換えられることはない（バイトで比較されるらしい）
    const registration = await registerServiceWorker()

    // ServiceWorker が登録されていないとかなり時間を要する模様（2 ~ 3 秒）
    const token = await getToken(this.messaging, {
      vapidKey: environment.FIREBASE_VAPID_KEY,
      serviceWorkerRegistration: registration,
    })
    console.log(token)
    this.userService.update("vapidKey", token)

    this.apiService
      .req("POST", "/user/vapid-key", { body: token })
      .pipe(take(1))
      .subscribe()
  }
}
