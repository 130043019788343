import { Injectable, inject } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private sanitizer = inject(DomSanitizer)

  info(message: string) {
    const toast = document.createElement("div")
    toast.classList.add("toast")
    // biome-ignore format:
    toast.innerHTML = (
      // biome-ignore lint:
      this.sanitizer.bypassSecurityTrustHtml(message) as any
    ).changingThisBreaksApplicationSecurity

    document.body.appendChild(toast)
    setTimeout(() => toast.classList.add("show"))

    setTimeout(() => {
      toast.classList.add("hide")
      setTimeout(() => document.body.removeChild(toast), 131)
    }, 4444)
  }

  error(message: string) {
    this.info(message)
  }
}
