export class AuthError extends Error {
  override readonly name = "AuthError" as const

  constructor(message?: string, options?: { cause: unknown }) {
    super(message, options)
    this.cause = options?.cause
  }
}

export class ApiError extends Error {
  override readonly name = "ApiError" as const

  constructor(message?: string, options?: { cause: unknown }) {
    super(message, options)
    this.cause = options?.cause
  }
}
