<div class="root" (scroll)="deformButton($event)">
  <div class="header">
    <c-search-form
      [id]="'game-search'"
      [placeholder]="'検索…'"
      [deleteButton]="true"
      (valueChange)="incrementalSearch($event)"
    />
    <c-native-select-box
      [items]="sorts"
      [(value)]="sort"
      (valueChange)="onChangeSort($event)"
    />
  </div>
  <div class="content_wrap">
    <mat-tab-group
      [preserveContent]="true"
      [animationDuration]="'313ms'"
      [disableRipple]="true"
      (selectedTabChange)="onChangeTab($event)"
    >
      <mat-tab [label]="'やりたい'" [labelClass]="['bold']">
        <c-game-list [games$]="wantGames$" [isLoading$]="isLoading$" />
      </mat-tab>
      <mat-tab [label]="'やった'" [labelClass]="['bold']">
        <c-game-list [games$]="playedGames$" [isLoading$]="isLoading$" />
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="floating_wrap">
    <div class="floating_button" [class.scrolled]="isScrolled" routerLink="/game/new">
      <fa-icon icon="plus" />
      @if (!isScrolled) {
      <span>追加</span>
      }
    </div>
  </div></div
>
