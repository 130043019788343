{
    "name": "kei-masumi-house",
    "version": "2.0.0",
    "private": true,
    "scripts": {
        "localhost": "ng serve --configuration localhost --host 0.0.0.0",
        "dev": "ng serve --configuration development --host 0.0.0.0",
        "build:sw": "ng build --configuration localhost",
        "build:dev": "ng build --configuration development",
        "build:prd": "ng build --configuration production",
        "watch": "ng build --watch --configuration development",
        "test": "vitest run --coverage",
        "test:watch": "vitest watch",
        "test:ci": "tsc --noEmit && npm run test",
        "biome:format": "biome format",
        "biome:lint": "biome check",
        "biome:ci": "biome ci"
    },
    "dependencies": {
        "@angular/animations": "^17.3.0",
        "@angular/cdk": "^17.3.0",
        "@angular/common": "^17.3.0",
        "@angular/compiler": "^17.3.0",
        "@angular/core": "^17.3.0",
        "@angular/fire": "^17.0.1",
        "@angular/forms": "^17.3.0",
        "@angular/material": "^17.3.0",
        "@angular/platform-browser": "^17.3.0",
        "@angular/platform-browser-dynamic": "^17.3.0",
        "@angular/platform-server": "^17.3.0",
        "@angular/router": "^17.3.0",
        "@angular/service-worker": "^17.3.0",
        "@datorama/akita": "^8.0.1",
        "@fortawesome/angular-fontawesome": "^0.14.0",
        "@fortawesome/fontawesome-svg-core": "^6.4.2",
        "@fortawesome/free-solid-svg-icons": "^6.4.2",
        "aws-amplify": "^6.0.7",
        "bignumber.js": "^9.1.2",
        "chart.js": "^4.4.1",
        "d3": "^7.8.5",
        "dayjs": "^1.11.9",
        "gsap": "^3.12.2",
        "h3": "^1.9.0",
        "rxjs": "~7.8.0",
        "socket.io-client": "^4.7.4",
        "throttle-debounce": "^5.0.0",
        "ts-results": "^3.3.0",
        "tslib": "^2.4.1",
        "zone.js": "~0.14.0"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^17.3.0",
        "@angular/cli": "^17.3.0",
        "@angular/compiler-cli": "^17.3.0",
        "@biomejs/biome": "^1.5.3",
        "@types/d3": "^7.4.3",
        "@types/throttle-debounce": "^5.0.0",
        "@vitest/coverage-v8": "^1.4.0",
        "jsdom": "^23.0.1",
        "prettier": "^3.1.1",
        "stylelint": "^14.16.1",
        "stylelint-config-standard-scss": "^6.1.0",
        "typescript": "~5.2.2",
        "vitest": "^1.4.0"
    },
    "//": "HTML フォーマット用の prettier は拡張のバンドルではなくインストールパッケージでないと動作しない"
}
