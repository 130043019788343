import { Injectable, inject } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { Observable, filter, map } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class UrlChangeStreamer {
  private router = inject(Router)

  navigationEnd$: Observable<string> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => (event as NavigationEnd).urlAfterRedirects),
  )
}
