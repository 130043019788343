import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"

@Component({
  selector: "c-check-label",
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: "./check-label.component.html",
  styleUrl: "./check-label.component.scss",
})
export class CheckLabelComponent {
  @Input({ required: true }) isOn!: boolean

  @Output() change = new EventEmitter<boolean>()

  @ViewChild("check") check!: ElementRef<HTMLDivElement>

  onChange() {
    this.check.nativeElement.classList.add("animation")
    setTimeout(() => {
      this.check.nativeElement.classList.remove("animation")
    }, 230)

    const newState = !this.isOn
    this.isOn = newState
    this.change.emit(newState)
  }
}
