import { animate, query, stagger, style, transition, trigger } from "@angular/animations"
import { CommonModule } from "@angular/common"
import { Component, DestroyRef, Input, OnInit, inject } from "@angular/core"
import { Router, RouterModule } from "@angular/router"
import { Observable, combineLatest, of } from "rxjs"

import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { SpinnerComponent } from "../../../shared/components/loading/spinner/spinner.component"
import { RepeatPipe } from "../../../shared/pipes/repeat.pipe"
import { GameDm } from "../../defs/types"
import { GamesQuery, GamesService } from "../../states/games"

@Component({
  selector: "c-game-list",
  standalone: true,
  imports: [CommonModule, RouterModule, RepeatPipe, SpinnerComponent],
  templateUrl: "./game-list.component.html",
  styleUrl: "./game-list.component.scss",
  animations: [
    trigger("show", [
      transition("void => initial", [
        query(
          ":enter",
          [
            style({ opacity: 0, transform: "translateY(-13px)" }),
            stagger(
              "0.037s",
              animate(
                "0.3s ease-out",
                style({ opacity: 1, transform: "translateY(0px)" }),
              ),
            ),
          ],
          { optional: true },
        ),
        query(":leave", [animate("500ms", style({ opacity: 0 }))], { optional: true }),
      ]),
    ]),
  ],
})
export class GameListComponent implements OnInit {
  @Input({ required: true }) games$!: Observable<Array<GameDm>>
  @Input({ required: true }) isLoading$!: Observable<boolean>

  private router = inject(Router)
  private gamesQuery = inject(GamesQuery)
  private gamesService = inject(GamesService)
  private destroyRef = inject(DestroyRef)

  animtaionState = !this.gamesQuery.initialized ? "initial" : "none"

  ngOnInit() {
    combineLatest([this.gamesService.recieveNewThumbnail$, this.games$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([_, games]) => {
        this.games$ = of(games)
      })
  }

  navigate(gameId: string, event: Event) {
    if ((event.target as HTMLElement).tagName !== "A") {
      this.router.navigateByUrl(`/game/${gameId}`)
    }
  }
}
