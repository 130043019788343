import { animate, keyframes, style, transition, trigger } from "@angular/animations"

export const imageAnimation = trigger("image", [
  transition("void => *", [
    animate(
      "0.27s ease-out",
      keyframes([
        style({ transform: "scale(0.8)", offset: 0 }),
        style({ transform: "scale(1.03)", offset: 0.75 }),
        style({ transform: "scale(1)", offset: 1.0 }),
      ]),
    ),
  ]),
])
