import { Component, inject } from "@angular/core"
import { Router } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { signOut } from "aws-amplify/auth"

import { environment } from "../../../environments/environment"
import { PageTitleComponent } from "../../features/global/components/page-title/page-title.component"
import { UserQuery } from "../../features/global/states/user"

@Component({
  standalone: true,
  imports: [PageTitleComponent, FontAwesomeModule],
  templateUrl: "./account.page.html",
  styleUrl: "./account.page.scss",
})
export class AccountPageComponent {
  private router = inject(Router)
  private userQuery = inject(UserQuery)

  userName = this.userQuery.name
  email = this.userQuery.email
  appVersion = environment.APP_VERSION

  async logOut() {
    if (confirm("ログアウトしますか？")) {
      await signOut()
      this.router.navigateByUrl("/login")
    }
  }
}
