import { CommonModule } from "@angular/common"
import { Component, Input, OnInit, inject } from "@angular/core"
import { Router, RouterModule } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { Observable, Subject, combineLatest, map, startWith } from "rxjs"

import { SearchFormComponent } from "../../../shared/components/form/search-form/search-form.component"
import { PaymentArchiveDm, PaymentDm } from "../../defs/types"
import { NumberCommaPipe } from "../../pipes/number-comma.pipe"
import { PaymentsQuery } from "../../states/payments"

type StreamToDisplay = Observable<Array<PaymentDm>> | Observable<Array<PaymentArchiveDm>>

@Component({
  selector: "c-payment-table",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SearchFormComponent,
    NumberCommaPipe,
    FontAwesomeModule,
  ],
  templateUrl: "./payment-table.component.html",
  styleUrl: "./payment-table.component.scss",
})
export class PaymentTableComponent implements OnInit {
  @Input() paymentsArchive$!: Observable<Array<PaymentArchiveDm>>

  private paymentsQuery = inject(PaymentsQuery)
  private router = inject(Router)

  payments$: StreamToDisplay = this.paymentsQuery.payments$
  _payments$!: StreamToDisplay
  triggerSearch$ = new Subject<string>()
  isArchivePage = false

  ngOnInit() {
    // プロパティとして渡すときはそのストリームを表示対象として優先させる（現状は精算履歴用）
    if (this.paymentsArchive$) {
      this.payments$ = this.paymentsArchive$
      this.isArchivePage = true
    }

    this._payments$ = combineLatest([
      this.payments$,
      this.triggerSearch$.pipe(startWith("")),
    ]).pipe(
      map(
        ([payments, searchWord]) =>
          payments.filter((payment) => {
            const serialized = Object.values(payment).join(" ").toLowerCase().normalize()
            return serialized.includes((searchWord ?? "").toLowerCase().normalize())
          }) || [],
      ),
    )
  }

  incrementalSearch(s: string) {
    this.triggerSearch$.next(s)
  }

  navigate(paymentId: string) {
    this.router.navigateByUrl(`/money/${paymentId}/edit`)
  }
}
