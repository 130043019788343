import { Injectable } from "@angular/core"
import { Store, StoreConfig } from "@datorama/akita"

import { GameDm, Sort } from "../../defs/types"

export interface GamesState {
  games: Array<GameDm>
  initialized: boolean
  sort: Sort
}

const createInitialState = (): GamesState => {
  return {
    games: [],
    initialized: false,
    sort: "created",
  }
}

@Injectable({
  providedIn: "root",
})
@StoreConfig({ name: "Games" })
export class GamesStore extends Store<GamesState> {
  constructor() {
    super(createInitialState())
  }
}
