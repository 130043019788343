<!--  -->
<div class="root">
  <c-search-form
    [placeholder]="'キーワードで絞り込み検索'"
    [deleteButton]="true"
    (valueChange)="incrementalSearch($event)"
  />
  @if (0 < ((_payments$ | async) ?? []).length) {
    <div class="table_wrap" [style.maxHeight]="isArchivePage ? '550px' : null">
      <div class="table">
        <div class="row header">
          <div class="cell">だれ</div>
          <div class="cell">金額</div>
          <div class="cell">摘要</div>
          <div class="cell">日時</div>
          @if (!isArchivePage) {
            <div class="cell"></div>
          }
        </div>
        @for (payment of (_payments$ | async)!; track payment.paymentId) {
          <div class="row">
            <div class="cell">{{ payment.userName }}</div>
            <div
              class="cell amount"
              [innerHTML]="(payment.amount | numberComma) + ' 円'"
            ></div>
            <div class="cell">
              @if (payment.adjust) {
                <span class="adjust_purpose">調整</span>
              }
              @if (payment.adjust && payment.purpose) {
                <span class="adjust_purpose">: </span>
              }
              {{ payment.purpose }}
            </div>
            <div class="cell">{{ payment.timestamp | date: "yyyy/MM/dd HH:mm" }}</div>
            @if (!isArchivePage) {
              <div class="cell" style="position: relative">
                <fa-icon icon="pen" (click)="navigate(payment.paymentId)" />
              </div>
            }
          </div>
        }
      </div>
    </div>
  } @else {
    <div class="no_payment">未精算項目はありません</div>
  }
</div>
