import { HttpClient } from "@angular/common/http"
import { Injectable, inject } from "@angular/core"
import { Router } from "@angular/router"
import { fetchAuthSession } from "aws-amplify/auth"
import { HTTPMethod } from "h3"
import { Observable, catchError, from, retry, switchMap } from "rxjs"

import { environment } from "../../../../environments/environment"
import { ToastService } from "../../shared/services/toast.service"
import { ApiError, AuthError } from "../defs/errors"

interface ApiOpts {
  headers?: Record<string, string>
  params?: Record<string, string | number | boolean>
  body?: object | string
}

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private client = inject(HttpClient)
  private router = inject(Router)
  private toastService = inject(ToastService)

  // biome-ignore lint:
  req<R = any>(
    method: HTTPMethod,
    apiPath: string,
    { headers = {}, params, body }: ApiOpts = {},
  ): Observable<R> {
    return from(fetchAuthSession()).pipe(
      switchMap((session) => {
        const idToken = session.tokens?.idToken?.toString()
        if (!idToken) {
          throw new AuthError("idToken is undefined")
        }

        // FIXME: Hack for access to smartphone from WSL 2
        const apiBase = environment.IS_PRD
          ? environment.API_BASE
          : window.location.hostname.includes("localhost")
            ? environment.API_BASE
            : environment.API_BASE.replace("localhost", window.location.hostname)

        return this.client
          .request<R>(method, `${apiBase}${apiPath}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              ...headers,
            },
            params,
            body,
          })
          .pipe(retry({ count: 3, delay: 333 }))
      }),
      catchError((err) => {
        console.error(err)

        if (err instanceof AuthError) {
          this.toastService.error("ログインが必要です")
          this.router.navigateByUrl("/login")
          throw err
        }

        this.toastService.error("ごめんなさい、連絡ください")
        throw new ApiError(err.status)
      }),
    )
  }
}
