<div class="title">最近の支払い</div>
<div class="root">
  <div class="rows">
    @if (!(isLoading$ | async)) {
      @for (payment of (payments$ | async)! | slice: 0 : 3; track payment.paymentId) {
        <div class="row">
          <div class="meta">
            <div class="who">
              支払った人：
              <span
                class="name"
                [ngClass]="{
                  positive: payment.userName !== myName,
                  negative: payment.userName === myName
                }"
              >
                {{ payment.userName }}
              </span>
            </div>
            <div class="datetime">{{
              payment.timestamp | date: "yyyy 年 M 月 d 日 HH:mm"
            }}</div>
          </div>
          <div class="value">
            <span [innerHTML]="payment.amount | numberComma"></span>
            <span class="yen">円</span>
          </div>
          <div class="purpose">
            <span>{{ payment.purpose }}</span>
          </div>
        </div>
      } @empty {
        <ng-container *ngTemplateOutlet="noPayment" />
      }
    } @else {
      <ng-container *ngTemplateOutlet="loading" />
    }
  </div>
  @if (0 < ((payments$ | async) ?? []).length) {
    <div class="more">
      <div class="link_area" (click)="openUnsettledPaymentsHalfModal()">
        <span>もっと見る</span>
        <fa-icon icon="angle-right" />
      </div>
    </div>
  }
</div>

<ng-template #loading>
  @for (x of 3 | repeat; track x) {
    <div class="row loading">
      <div class="skeleton l"></div>
      <div class="skeleton m"></div>
      <div class="skeleton s"></div>
    </div>
  }
</ng-template>

<ng-template #noPayment>
  <div class="no_payment">まだ何もありません 😚</div>
</ng-template>

<ng-template #paymentsHalfModal>
  <c-payments-half-modal />
</ng-template>
