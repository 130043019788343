import { Injectable } from "@angular/core"

@Injectable({
  providedIn: "root",
})
export class NavigationBackManager {
  private _isManuallyBacked = false

  get isManuallyBacked(): boolean {
    return this._isManuallyBacked
  }

  set isManuallyBacked(v: boolean) {
    this._isManuallyBacked = v
  }
}
