import { Component, inject } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Observable } from "rxjs"

import { PageTitleComponent } from "../../../../features/global/components/page-title/page-title.component"
import { PaymentTableComponent } from "../../../../features/money/components/payment-table/payment-table.component"
import { PaymentArchiveDm } from "../../../../features/money/defs/types"
import { PaymentsArchiveQuery } from "../../../../features/money/states/payments-archive"

@Component({
  standalone: true,
  imports: [PageTitleComponent, PaymentTableComponent],
  templateUrl: "./money-archive-detail.page.html",
  styleUrl: "./money-archive-detail.page.scss",
})
export class MoneyArchiveDetailPageComponent {
  private route = inject(ActivatedRoute)
  private paymentArchiveQuery = inject(PaymentsArchiveQuery)

  paymentArchive$!: Observable<Array<PaymentArchiveDm>>

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.paymentArchive$ = this.paymentArchiveQuery.findById$(params.id)
    })
  }
}
