<div class="root">
  <c-page-title [noBackButton]="true">アカウント</c-page-title>
  <div class="block_wrap">
    <div class="title">アカウント情報</div>
    <div class="block">
      <div class="row">
        <div class="label">ユーザー名</div>
        <div class="value">{{ userName }}</div>
        <fa-icon icon="angle-right" />
      </div>
      <div class="row">
        <div class="label">メールアドレス</div>
        <div class="value">{{ email }}</div>
        <fa-icon icon="angle-right" />
      </div>
      <div class="row">
        <div class="label">パスワード</div>
        <div class="value"></div>
        <fa-icon icon="angle-right" />
      </div>
    </div>
    <div class="description">
      これらの変更はまだアプリ上からできません。需要がありそうだったらつくります！
    </div>
  </div>
  <div class="block_wrap">
    <div class="title">ソフトウェア情報</div>
    <div class="block">
      <div class="row">
        <div class="label">バージョン</div>
        <div class="value">{{ appVersion }}</div>
      </div>
    </div>
  </div>
  <div class="block_wrap">
    <div class="block">
      <div class="row" (click)="logOut()">
        <div class="label red">ログアウト</div>
      </div>
    </div>
  </div>
</div>
