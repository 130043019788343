import { Component, inject } from "@angular/core"
import { ActivatedRoute } from "@angular/router"

import { GameBasePageComponent } from "../../../features/game/components/game-base-page/game-base-page.component"
import { GameDm } from "../../../features/game/defs/types"
import { GamesQuery } from "../../../features/game/states/games"

@Component({
  standalone: true,
  imports: [GameBasePageComponent],
  templateUrl: "./game-edit.page.html",
})
export class GameEditPageComponent {
  private route = inject(ActivatedRoute)
  private gamesQuery = inject(GamesQuery)

  gameToEdit!: GameDm

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.gameToEdit = this.gamesQuery.findById(params.id)
    })
  }
}
