import { Component } from "@angular/core"

import { MoneyBasePageComponent } from "../../../features/money/components/money-base-page/money-base-page.component"

@Component({
  standalone: true,
  imports: [MoneyBasePageComponent],
  templateUrl: "./money-new.page.html",
})
export class MoneyNewPageComponent {}
