import { Component, Input, forwardRef } from "@angular/core"
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms"

@Component({
  selector: "c-base-checkbox",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./base-checkbox.component.html",
  styleUrl: "./base-checkbox.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseCheckboxComponent),
      multi: true,
    },
  ],
})
export class BaseCheckboxComponent implements ControlValueAccessor {
  @Input({ required: true }) label!: string
  @Input() value!: string

  id = Math.random().toString(36).slice(2, 9)
  disabled = false
  onChange = (_value: string) => {}
  onTouched = () => {}

  writeValue(value: string) {
    this.value = value
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  onBlur() {
    this.onTouched()
  }
}
