<div class="root">
  @if (label) {
    <label [for]="id">{{ label }}</label>
  }
  <input
    [type]="type ?? 'text'"
    [id]="id"
    [name]="id"
    [placeholder]="placeholder ? placeholder : ''"
    [disabled]="disabled"
    [ngStyle]="{ 'padding-right': deleteButton ? '2.7em' : null }"
    [(ngModel)]="_value"
    (ngModelChange)="onValueChange($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
  @if (deleteButton) {
    <fa-icon icon="times" (click)="deleteText()" />
  }
</div>
