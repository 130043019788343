import { ChangeDetectionStrategy, Component, OnInit, inject } from "@angular/core"
import { RouterOutlet } from "@angular/router"
import { FaIconLibrary } from "@fortawesome/angular-fontawesome"
import {
  faAngleLeft,
  faAngleRight,
  faArrowUpRightFromSquare,
  faChartColumn,
  faCircleCheck,
  faCircleQuestion,
  faClockRotateLeft,
  faEllipsisVertical,
  faFile,
  faGamepad,
  faImage,
  faList,
  faPen,
  faPenToSquare,
  faPlus,
  faTimes,
  faTrashCan,
  faUser,
  faWallet,
} from "@fortawesome/free-solid-svg-icons"

import { PlatformLocation } from "@angular/common"
import { GlobalBottomNaviComponent } from "./features/global/components/global-bottom-navi/global-bottom-navi.component"
import { NavigationBackManager } from "./features/global/services/navigation-back-manager.service"
import { animations } from "./styles/page-transitions"

@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, GlobalBottomNaviComponent],
  template: `
    <div [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet" />
    </div>
    <c-global-bottom-navi />
  `,
  animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private location = inject(PlatformLocation)
  private navigationBackManager = inject(NavigationBackManager)

  isPoped = false

  constructor(library: FaIconLibrary) {
    library.addIcons(
      faTimes,
      faCircleCheck,
      faWallet,
      faGamepad,
      faCircleQuestion,
      faUser,
      faAngleLeft,
      faAngleRight,
      faPen,
      faList,
      faClockRotateLeft,
      faChartColumn,
      faFile,
      faTrashCan,
      faPlus,
      faArrowUpRightFromSquare,
      faPenToSquare,
      faEllipsisVertical,
      faImage,
    )
  }

  // アプリケーションで定義された戻るボタンで戻ったときには backward アニメーションが適用されてほしいが、
  // ブラウザの戻る機能（PWA においては右スワイプ）で戻った場合にはアニメーションを実行させないようにする

  ngOnInit() {
    this.location.onPopState(() => {
      this.isPoped = true
    })
  }

  prepareRoute(outlet: RouterOutlet): string {
    if (this.isPoped && this.navigationBackManager.isManuallyBacked) {
      this.isPoped = false
      this.navigationBackManager.isManuallyBacked = false
      return outlet.activatedRouteData.animation
    } else if (this.isPoped) {
      this.isPoped = false
      return "none"
    } else {
      return outlet.activatedRouteData.animation
    }
  }
}
