import { Location } from "@angular/common"
import { Component, Input, inject } from "@angular/core"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"

import { NavigationBackManager } from "../../services/navigation-back-manager.service"

@Component({
  selector: "c-page-title",
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: "./page-title.component.html",
  styleUrl: "./page-title.component.scss",
})
export class PageTitleComponent {
  @Input() noBackButton = false

  private location = inject(Location)
  private navigationBackManager = inject(NavigationBackManager)

  back() {
    this.navigationBackManager.isManuallyBacked = true
    this.location.back()
  }
}
