<div class="root">
  <c-page-title>ゲームの詳細</c-page-title>
  <div class="thumbnail">
    <img [src]="game.image" [class.generating]="game.image.includes('no-image')" />
    @if (game.image.includes("no-image")) {
    <c-spinner [color]="'#e77e12'" />
    }
  </div>
  <div class="content_wrap">
    <div class="title">
      <span>{{ game.title }}</span>
    </div>
    <div class="memo_wrap">
      <div class="memo">
        @if (game.memo) { {{ game.memo }} } @else {
        <span class="no_memo">めもがありません</span>
        }
      </div>
    </div>
    @if (game.url) {
    <div class="url">
      <a [href]="game.url" target="_blank">{{ game.url }}</a>
      <fa-icon icon="arrow-up-right-from-square" />
    </div>
    }
  </div>
  <div class="bottom_wrap">
    <div class="info_wrap">
      <div class="row">
        <div class="label">追加した日時</div>
        <div class="value">{{ game.createdAt | date: "yyyy/M/d HH:mm" }}</div>
      </div>
      <div class="row">
        <div class="label">更新した日時</div>
        <div class="value">{{ game.updatedAt | date: "yyyy/M/d HH:mm" }}</div>
      </div>
      @if (game.played) {
      <div class="row">
        <div class="label">遊び終わった日時</div>
        <div class="value">{{ game.playedAt | date: "yyyy/M/d HH:mm" }}</div>
      </div>
      }
      <div class="row">
        <div class="label">追加した人</div>
        <div class="value">{{ game.createdBy }}</div>
      </div>
    </div>
    <div class="buttons">
      <c-base-button
        class="expand radius"
        [height]="'43px'"
        [routerLink]="'/game/' + game.gameId + '/edit'"
        >編集する
        <fa-icon icon="pen-to-square" />
      </c-base-button>
      @if (!game.played) {
      <c-base-button
        class="expand radius"
        [height]="'43px'"
        [spinnerColor]="'var(--color-text)'"
        [isSubmitting]="isPlayeding$ | async"
        (click)="played()"
        >もう遊んだ！
      </c-base-button>
      }
    </div>
  </div>
</div>
