import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from "@angular/core"
import * as d3 from "d3"

import { UserName } from "../../../global/defs/types"

export interface PieDatum {
  label: UserName
  value: number
}

// 勉強も兼ねて d3 を使ってみたが、恐ろしくわかりづらいしとてもじゃないが覚えられない。
// さらにまとまったサンプルもなく、GPT もドキュメントの例も動くものは用意できない。
// よほど込み入った要件じゃない限り chart.js でよいという実感を得た。

@Component({
  selector: "c-payment-count-pie-chart",
  standalone: true,
  imports: [],
  template: `
    <div #chart style="text-align: center"></div>
  `,
})
export class PaymentCountPieChartComponent implements AfterViewInit, OnChanges {
  @Input({ required: true }) data!: [PieDatum, PieDatum]
  @Input({ required: true }) size!: number

  @ViewChild("chart", { static: true }) private chartContainer!: ElementRef

  existsChart = false

  ngAfterViewInit() {
    this.drawChart()
  }

  ngOnChanges() {
    if (this.existsChart) {
      this.drawChart()
    }
  }

  private drawChart() {
    if (this.existsChart) {
      d3.select(this.chartContainer.nativeElement).selectAll("*").remove()
    }

    const radius = this.size / 2
    const color = d3.scaleOrdinal(["#f9d3b3", "#f4b3ad"])
    const arc = d3.arc<d3.PieArcDatum<PieDatum>>().innerRadius(0).outerRadius(radius)

    const svg = d3
      .select(this.chartContainer.nativeElement)
      .append("svg")
      .attr("width", this.size)
      .attr("height", this.size)
      .append("g")
      .attr("transform", `translate(${this.size / 2}, ${this.size / 2})`)

    const pie = d3
      .pie<PieDatum>()
      .sort(null)
      .value((v) => v.value)

    const arcs = svg
      .selectAll(".arc")
      .data(pie(this.data))
      .enter()
      .append("g")
      .attr("class", "arc")

    arcs
      .append("path")
      .attr("fill", (_, i) => color(i.toString()))
      .transition()
      .duration(777)
      // @ts-ignore
      .attrTween("d", (d) => {
        const i = d3.interpolate(d.startAngle + 0.1, d.endAngle)
        return (t) => {
          d.endAngle = i(t)
          return arc(d)
        }
      })

    const pieElems = svg
      .selectAll(".pie-elems")
      .data(pie(this.data))
      .enter()
      .append("g")
      .attr("class", "pie-elems")

    pieElems
      .append("text")
      .attr("transform", (d) => `translate(${arc.centroid(d)})`)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("fill", "var(--color-text)")
      .each(function (d) {
        d3.select(this)
          .append("tspan")
          .attr("font-size", 17)
          .attr("font-weight", "bold")
          .text(d.data.value)
          // ===
          .append("tspan")
          .attr("dx", "2px")
          .attr("font-size", 13)
          .text("件")
      })

    this.existsChart = true
  }
}
