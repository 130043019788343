import { Component, OnInit, inject } from "@angular/core"
import { RouterModule } from "@angular/router"

import { RegisterPushNotificationService } from "../../features/global/services/register-push-notification.service"
import { UserQuery } from "../../features/global/states/user"
import { AmountDetailsComponent } from "../../features/money/components/amount-details/amount-details.component"
import { DutchAmountComponent } from "../../features/money/components/dutch-amount/dutch-amount.component"
import { LatestPaymentsComponent } from "../../features/money/components/latest-payments/latest-payments.component"
import { OtherFeatureLinksComponent } from "../../features/money/components/other-feature-links/other-feature-links.component"
import { PaymentsQuery, PaymentsService } from "../../features/money/states/payments"
import {
  PaymentsArchiveQuery,
  PaymentsArchiveService,
} from "../../features/money/states/payments-archive"
import { BaseButtonComponent } from "../../features/shared/components/button/base-button/base-button.component"

@Component({
  standalone: true,
  imports: [
    RouterModule,
    DutchAmountComponent,
    AmountDetailsComponent,
    LatestPaymentsComponent,
    OtherFeatureLinksComponent,
    BaseButtonComponent,
  ],
  templateUrl: "./money.page.html",
  styleUrl: "./money.page.scss",
})
export class MoneyPageComponent implements OnInit {
  private paymentsQuery = inject(PaymentsQuery)
  private paymentsService = inject(PaymentsService)
  private paymentsArchiveQuery = inject(PaymentsArchiveQuery)
  private paymentsArchiveService = inject(PaymentsArchiveService)
  private registerPushNotificationService = inject(RegisterPushNotificationService)

  payments$ = this.paymentsQuery.payments$
  paymentsArchive$ = this.paymentsArchiveQuery.paymentsArchive$
  isLoading$ = this.paymentsQuery.selectLoading()

  ngOnInit() {
    if (!this.paymentsQuery.initialized) {
      this.paymentsService.init()
    } else {
      this.paymentsService.fetchAndStore({ useLoading: false })
    }

    if (!this.paymentsService.hasWebSocketConnection) {
      this.paymentsService.connectWebSocket()
    }

    if (!this.paymentsArchiveQuery.initialized) {
      this.paymentsArchiveService.init()
    } else {
      this.paymentsArchiveService.fetchAndStore({ useLoading: false })
    }

    this.registerPushNotificationService.register()
  }
}
