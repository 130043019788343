import { CommonModule } from "@angular/common"
import { Component, Input, OnInit, inject } from "@angular/core"
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import Bn from "bignumber.js"
import { Subject, take } from "rxjs"

import { PageTitleComponent } from "../../../../features/global/components/page-title/page-title.component"
import { ApiService } from "../../../../features/global/services/api.service"
import { UserQuery } from "../../../../features/global/states/user"
import { PaymentDm, ReqPaymentDto } from "../../../../features/money/defs/types"
import { BaseCheckboxComponent } from "../../../../features/shared/components/form/base-checkbox/base-checkbox.component"
import { BaseRadioButtonGroupComponent } from "../../../../features/shared/components/form/base-radio-button-group/base-radio-button-group.component"
import { BaseTextInputComponent } from "../../../../features/shared/components/form/base-text-input/base-text-input.component"
import { BaseTextareaComponent } from "../../../../features/shared/components/form/base-textarea/base-textarea.component"
import { TwoChoicesTabComponent } from "../../../../features/shared/components/others/two-choices-tab/two-choices-tab.component"
import { ToastService } from "../../../../features/shared/services/toast.service"
import { BaseButtonComponent } from "../../../shared/components/button/base-button/base-button.component"
import { NumberCommaPipe } from "../../pipes/number-comma.pipe"

type TabType = "dutch" | "adjust"

@Component({
  selector: "c-money-base-page",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    PageTitleComponent,
    TwoChoicesTabComponent,
    BaseButtonComponent,
    BaseRadioButtonGroupComponent,
    BaseTextInputComponent,
    BaseTextareaComponent,
    BaseCheckboxComponent,
    NumberCommaPipe,
  ],
  templateUrl: "./money-base-page.component.html",
  styleUrl: "./money-base-page.component.scss",
})
export class MoneyBasePageComponent implements OnInit {
  @Input({ required: true }) mode!: "new" | "edit"
  @Input() paymentToEdit!: PaymentDm

  private userQuery = inject(UserQuery)
  private formBuilder = inject(FormBuilder)
  private apiService = inject(ApiService)
  private toastService = inject(ToastService)
  private router = inject(Router)

  userName = this.userQuery.name
  tabTypes: Array<TabType> = ["dutch", "adjust"]
  paymentDirections = [
    [
      { label: "圭の支払いとして扱う", value: "圭" },
      { label: "万純の支払いとして扱う", value: "万純" },
    ],
    [
      { label: "圭が万純の支払いを立て替えた", value: "圭" },
      { label: "万純が圭の支払いを立て替えた", value: "万純" },
    ],
  ]
  amountLabel = ["割り勘前の金額合計", "調整金額"]
  form = this.formBuilder.nonNullable.group({
    adjust: [this.tabTypes[0], Validators.required],
    user_name: [this.userName, Validators.required],
    amount: ["", [Validators.required, Validators.min(1)]],
    calculator: [""], // For only `this.form.disable()` when form is posted
    purpose: ["", Validators.required],
    notification: [false, Validators.required],
    button: null,
  })
  amountForComma = ""
  currentFocus: "form" | "display" = "display"
  currentTabIndex = this.tabTypes.indexOf(this.form.value.adjust!)
  isSubmitting$ = new Subject<boolean>()

  ngOnInit() {
    if (this.mode === "edit") {
      this.form.controls.adjust.disable()
      this.currentTabIndex = this.tabTypes.indexOf(
        this.paymentToEdit.adjust ? "adjust" : "dutch",
      )
      this.form.patchValue({
        adjust: this.paymentToEdit.adjust ? "adjust" : "dutch",
        user_name: this.paymentToEdit.userName,
        amount: this.paymentToEdit.amount.toString(),
        purpose: this.paymentToEdit.purpose,
        notification: true,
      })
    }
  }

  onTabTypeChange(value: TabType) {
    this.currentTabIndex = this.tabTypes.indexOf(value)
  }

  onAmountFocus() {
    this.currentFocus = "form"
  }

  onAmountBlur() {
    if (new Bn(this.form.value.amount!).isNaN()) {
      this.form.patchValue({ amount: "" })
    }

    this.amountForComma = Number(this.form.value.amount).toLocaleString("en-US")

    if (this.form.value.amount !== "" && this.form.value.amount !== "0") {
      this.currentFocus = "display"
    }
  }

  focusAmount() {
    document.getElementById("amount-form")!.focus()
  }

  onAmountCalculatorChange(value: string) {
    const total = value
      .split(/,|\n/)
      .map((numString) => numString.trim())
      .reduce((prev, current) => Number(prev) + Number(current), 0)

    if (!new Bn(total).isNaN()) {
      this.form.patchValue({ amount: total.toString() })
    }
  }

  postPayment() {
    this.form.updateValueAndValidity()
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => this.form.get(key)?.markAsDirty())
      this.toastService.error("赤いところを確認してね")
      return
    }

    this.form.disable()
    this.isSubmitting$.next(true)

    const formSnapshot = this.form.value
    const postData: ReqPaymentDto = {
      adjust: formSnapshot.adjust === "adjust",
      user_name: formSnapshot.user_name!,
      amount: Number(formSnapshot.amount!),
      purpose: formSnapshot.purpose!,
      notification: formSnapshot.notification!,
    }

    this.apiService
      .req("POST", "/payments", { body: postData })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigateByUrl("/money")
        },
        error: () => {
          this.form.enable()
          this.isSubmitting$.next(false)
        },
      })
  }

  putPayment() {
    this.form.updateValueAndValidity()
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((key) => this.form.get(key)?.markAsDirty())
      this.toastService.error("赤いところを確認してね")
      return
    }

    this.form.disable()
    this.isSubmitting$.next(true)

    const formSnapshot = this.form.value
    const putData: Omit<ReqPaymentDto, "adjust"> = {
      user_name: formSnapshot.user_name!,
      amount: Number(formSnapshot.amount!),
      purpose: formSnapshot.purpose!,
      notification: formSnapshot.notification!,
    }

    this.apiService
      .req("PUT", `/payments/${this.paymentToEdit.paymentId}`, {
        body: putData,
      })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigateByUrl("/money")
        },
        error: () => {
          this.form.enable()
          this.isSubmitting$.next(false)
        },
      })
  }

  deletePayment() {
    if (confirm("本当にこの支払いを削除してもいいですか？")) {
      this.form.disable()
      this.isSubmitting$.next(true)

      this.apiService
        .req("DELETE", `/payments/${this.paymentToEdit.paymentId}`)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.router.navigateByUrl("/money")
          },
          error: () => {
            this.form.enable()
            this.isSubmitting$.next(false)
          },
        })
    }
  }
}
