<div class="root">
  @if (!(isLoading$ | async)) {
    <div class="row">
      <div class="label">未精算額の合計</div>
      <div class="value">
        <span [innerHTML]="abs(round(total)) | numberComma"></span>
        <span class="yen">円</span>
      </div>
    </div>
    <div class="row">
      <div class="label">あなたが払った額の合計</div>
      <div class="value">
        <span [innerHTML]="abs(round(totalIPaid)) | numberComma"></span>
        <span class="yen">円</span>
      </div>
    </div>
    <div class="row">
      <div class="label">あなたが受け取れる概算額</div>
      <div class="value">
        <span [innerHTML]="abs(round(dutchAmount)) | numberComma"></span>
        <span class="yen">円</span>
      </div>
    </div>
    <div class="hr"></div>
    <div class="row">
      <div class="label">これまで二人が払った総額</div>
      <div class="value">
        <span [innerHTML]="abs(round(totalAmount)) | numberComma"></span>
        <span class="yen">円</span>
      </div>
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="loading" />
  }
</div>

<ng-template #loading>
  <div class="skeleton"></div>
  <div class="skeleton"></div>
  <div class="skeleton"></div>
  <div class="skeleton big"></div>
</ng-template>
