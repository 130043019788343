import { AnimationEvent } from "@angular/animations"
import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog"
import { Platform } from "@angular/cdk/platform"
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject,
} from "@angular/core"
import { SafeHtml } from "@angular/platform-browser"

import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { AskAgainQuery, AskAgainService, AskAgainState } from "../../states/ask-again"

// Only half-modal
type SimpleModal = {
  templateType: "simple"
  context: {
    title?: string
    content: string | SafeHtml
    height?: number
  }
}

type ConfirmModal = {
  templateType: "confirm"
  context: {
    title?: string
    content: string | SafeHtml
    btnText: string
    askAgain?: {
      text?: string
      stateKey: string
    }
  }
}

type ActionModal = {
  templateType: "action"
  context: {
    title?: string
    content: string | SafeHtml | TemplateRef<null>
    btnText: string
  }
}

type FreeModal = {
  templateType: "free"
  context: {
    content: string | SafeHtml | TemplateRef<null>
    height?: number
  }
}

export type ModalType = SimpleModal | ConfirmModal | ActionModal | FreeModal

type CloseEvent = "OK"

@Component({ template: "" })
export abstract class ModalBase implements OnInit, AfterViewInit {
  @ViewChild("container", { read: ViewContainerRef }) container!: ViewContainerRef
  @ViewChild("simple_t", { read: TemplateRef }) simpleTemplate!: TemplateRef<ModalType>
  @ViewChild("confirm_t", { read: TemplateRef }) confirmTemplate!: TemplateRef<ModalType>
  @ViewChild("action_t", { read: TemplateRef }) actionTemplate!: TemplateRef<ModalType>
  @ViewChild("free_t", { read: TemplateRef }) freeTemplate!: TemplateRef<ModalType>
  @ViewChild("action_container", { read: ViewContainerRef })
  actionContainer!: ViewContainerRef
  @ViewChild("free_container", { read: ViewContainerRef })
  freeContainer!: ViewContainerRef

  protected data = inject(DIALOG_DATA) as ModalType
  protected dialogRef = inject(DialogRef<CloseEvent>)
  protected cd = inject(ChangeDetectorRef)
  protected askAgainQuery = inject(AskAgainQuery)
  protected askAgainService = inject(AskAgainService)
  protected platform = inject(Platform)
  protected destroyRef = inject(DestroyRef)

  leaving = false
  closeEvent?: CloseEvent = undefined
  isAskAgain!: boolean
  halfModalHeight = "auto"

  ngOnInit() {
    this.dialogRef.backdropClick
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.close())

    this.dialogRef.keydownEvents
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((e: KeyboardEvent) => {
        if (e.key === "Escape") this.close()
      })
  }

  ngAfterViewInit() {
    let template: TemplateRef<ModalType>
    switch (this.data.templateType) {
      case "simple":
        template = this.simpleTemplate
        if (this.data.context.height) {
          this.halfModalHeight = `${this.data.context.height}svh !important`
        }
        break
      case "confirm":
        template = this.confirmTemplate
        if (this.data.context.askAgain) {
          this.isAskAgain =
            this.askAgainQuery.getValue()[
              this.data.context.askAgain.stateKey as keyof AskAgainState
            ]
        }
        break
      case "action":
        template = this.actionTemplate
        break
      case "free":
        template = this.freeTemplate
        if (this.data.context.height) {
          this.halfModalHeight = `${this.data.context.height}svh !important`
        }
        break
    }
    this.container.createEmbeddedView(template, this.data)
    this.cd.detectChanges()

    if (this.data.context.content instanceof TemplateRef && this.actionContainer) {
      this.actionContainer.createEmbeddedView(this.data.context.content)
    }
    if (this.data.context.content instanceof TemplateRef && this.freeContainer) {
      this.freeContainer.createEmbeddedView(this.data.context.content)
    }

    this.cd.detectChanges()
  }

  // All close events must use this method
  close(event?: CloseEvent) {
    this.closeEvent = event

    // The animation will start to close
    this.leaving = true
    this.dialogRef.overlayRef.backdropElement?.classList.add("leave")
  }

  animationDone(event: AnimationEvent) {
    if (event.toState === "hidden") {
      this.dialogRef.close(this.closeEvent)
    }
  }

  isString(value: unknown) {
    return typeof value === "string"
  }
}
