import { CommonModule } from "@angular/common"
import { Component, EventEmitter, Input, Output, forwardRef } from "@angular/core"
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms"

@Component({
  selector: "c-base-textarea",
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: "./base-textarea.component.html",
  styleUrl: "./base-textarea.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseTextareaComponent),
      multi: true,
    },
  ],
})
export class BaseTextareaComponent {
  @Input() label?: string
  @Input() id = "id-" + Math.random().toString(36).slice(2, 9)
  @Input() value?: string
  @Input() placeholder?: string
  @Input() height = "100%"

  @Output() valueChange = new EventEmitter<string>()
  @Output() focusEvent = new EventEmitter<void>()
  @Output() blurEvent = new EventEmitter<void>()

  _value!: string
  disabled = false
  onChange = (_value: string) => {}
  onTouched = () => {}

  ngOnInit() {
    if (this.value) {
      this._value = this.value
    }
  }

  writeValue(value: string) {
    this._value = value
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  onValueChange(value: string) {
    this.onChange(value)
    this.valueChange.emit(value)
  }

  onFocus() {
    this.focusEvent.emit()
  }

  onBlur() {
    this.onTouched()
    this.blurEvent.emit()
  }
}
