<div class="root p_login">
  <div class="title">ログイン</div>
  <div class="form">
    <form (ngSubmit)="logIn(user)">
      <!-- `[attr.disabled]` を使っているのはテンプレート駆動フォームであるため -->
      <c-base-form
        [label]="'メールアドレス'"
        [type]="'email'"
        [id]="'username'"
        [(value)]="user.username"
        [placeholder]="'name@example.com'"
        [attr.disabled]="isLoggingIn$ | async"
        (focusEvent)="onFocus()"
      />
      <c-base-form
        [label]="'パスワード'"
        [type]="'password'"
        [id]="'password'"
        [attr.disabled]="isLoggingIn$ | async"
        [(value)]="user.password"
        (focusEvent)="onFocus()"
      />
      <c-base-button
        class="primary expand"
        [height]="'41px'"
        [attr.disabled]="isLoggingIn$ | async"
        (click)="logIn(user)"
        >ログイン
      </c-base-button>
    </form>
    <div class="text">
      <span (click)="forgot()">パスワードを忘れましたか？</span>
    </div>
  </div>
</div>
