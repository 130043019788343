import { UserName } from "../../global/defs/types"

export interface PaymentDm {
  paymentId: string
  userName: UserName
  amount: number
  purpose: string
  adjust?: boolean
  timestamp: number
}

export interface ResPaymentDto {
  payment_id: string
  user_name: UserName
  amount: number
  purpose: string
  timestamp: number
  adjust?: boolean
}

export interface ReqPaymentDto {
  adjust: boolean
  user_name: UserName
  amount: number
  purpose: string
  notification: boolean
}

export const transformPaymentResToDm = (value: ResPaymentDto): PaymentDm => {
  return {
    paymentId: value.payment_id,
    userName: value.user_name,
    amount: value.amount,
    purpose: value.purpose,
    adjust: value.adjust,
    timestamp: value.timestamp,
  }
}

export interface ResPaymentArchiveDto {
  payment_id: string
  user_name: UserName
  amount: number
  purpose: string
  adjust?: boolean
  timestamp: number
  archive_id?: string
  settled_at?: string
}

export interface PaymentArchiveDm {
  paymentId: string
  userName: UserName
  amount: number
  purpose: string
  adjust?: boolean
  timestamp: number
  archiveId?: string
  settledAt?: string
}

export const transformPaymentArchiveResToDm = (
  value: Array<ResPaymentArchiveDto>,
): Array<PaymentArchiveDm> => {
  return value.map((payment) => {
    return {
      paymentId: payment.payment_id,
      userName: payment.user_name,
      amount: payment.amount,
      purpose: payment.purpose,
      adjust: payment.adjust,
      timestamp: payment.timestamp,
      archiveId: payment.archive_id,
      settledAt: payment.settled_at,
    }
  })
}
