<div class="root">
  <div
    class="card"
    [ngClass]="{ positive: isPositive === true, negative: isPositive === false }"
    [class.hide]="isLoading$ | async"
  >
    <div class="title">いまの割り勘額</div>
    <div class="value">
      @if (amount !== 0) {
        @if (isPositive) {
          {{ "+ " }}
        } @else {
          {{ "- " }}
        }
      }
      <span [innerHTML]="abs(round(amount)) | numberComma"></span>
      <span class="yen">円</span>
    </div>
    <div class="desc">多く払っている人：{{ whoToPay ?? "-" }}</div>
  </div>
  <div class="card loading" [class.hide]="!(isLoading$ | async)">
    <div class="skeleton"></div>
  </div>
</div>
