import { inject } from "@angular/core"
import { CanActivateFn, Router } from "@angular/router"

import { ToastService } from "../../shared/services/toast.service"
import { UserQuery } from "../states/user"

export const loginGuard: CanActivateFn = () => {
  const userQuery = inject(UserQuery)
  const router = inject(Router)
  const toast = inject(ToastService)

  if (userQuery.isLoggedIn) {
    return true
  } else {
    toast.info("ログインしてください")
    return router.createUrlTree(["/login"])
  }
}
