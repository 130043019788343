import { Injectable, inject } from "@angular/core"
import { Query } from "@datorama/akita"

import { GameDm, Sort } from "../../defs/types"
import { GamesState, GamesStore } from "./games.store"

@Injectable({
  providedIn: "root",
})
export class GamesQuery extends Query<GamesState> {
  protected override store = inject(GamesStore)

  games$ = this.select((gamesState) => gamesState.games)

  // biome-ignore lint:
  constructor(store: GamesStore) {
    super(store)
  }

  get initialized(): boolean {
    return this.getValue().initialized
  }

  get sort(): Sort {
    return this.getValue().sort
  }

  get randomTitle(): string {
    const length = this.getValue().games.length
    const random = Math.floor(Math.random() * length)
    return this.getValue().games[random]!.title
  }

  findById(gameId: string): GameDm {
    return this.getValue().games.find((game) => game.gameId === gameId)!
  }
}
