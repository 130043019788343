import "zone.js"

import { provideHttpClient } from "@angular/common/http"
import { importProvidersFrom, isDevMode } from "@angular/core"
import { initializeApp, provideFirebaseApp } from "@angular/fire/app"
import { getMessaging, provideMessaging } from "@angular/fire/messaging"
import { bootstrapApplication } from "@angular/platform-browser"
import { provideAnimations } from "@angular/platform-browser/animations"
import { provideRouter, withInMemoryScrolling } from "@angular/router"
import { provideServiceWorker } from "@angular/service-worker"

import { AppComponent } from "./app/app.component"
import { routes } from "./app/routes"
import { environment } from "./environments/environment"
import { initAkitaConfigs, provideAkitaDevtools } from "./plugins/akita"
import { initChartJsConfigs } from "./plugins/chartjs"
import { initCognitoConfigs } from "./plugins/cognito"

initCognitoConfigs()
initAkitaConfigs()
initChartJsConfigs()

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ),
    provideHttpClient(),
    provideAnimations(),
    provideAkitaDevtools(),
    provideServiceWorker("ngsw-worker.js", {
      enabled: !isDevMode(),
      registrationStrategy: "registerWhenStable:30000",
    }),
    importProvidersFrom(provideFirebaseApp(() => initializeApp(environment.FIREBASE))),
    importProvidersFrom(provideMessaging(() => getMessaging())),
  ],
})
