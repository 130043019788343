import { Component, Input } from "@angular/core"

@Component({
  selector: "c-spinner",
  standalone: true,
  templateUrl: "./spinner.component.html",
  styleUrl: "./spinner.component.scss",
})
export class SpinnerComponent {
  @Input() color?: string = "#8b8b8b"
}
