<div class="root">
  <div class="feature unsettled_list" (click)="openUnsettledPaymentsHalfModal()">
    <div class="circle">
      <fa-icon icon="list" />
    </div>
    <div class="text">未精算項目</div>
  </div>
  <div class="feature settlement_history" routerLink="/money/archive">
    <div class="circle">
      <fa-icon icon="clock-rotate-left" />
    </div>
    <div class="text">精算履歴</div>
  </div>
  <div class="feature analysis" routerLink="/money/analysis">
    <div class="circle">
      <fa-icon icon="chart-column" />
    </div>
    <div class="text">統計</div>
  </div>
  <div class="feature nanka" (click)="unimplemented()">
    <div class="circle">
      <fa-icon icon="file" />
    </div>
    <div class="text">なんか</div>
  </div>
</div>

<ng-template #paymentsHalfModal>
  <c-payments-half-modal />
</ng-template>
