import { CommonModule } from "@angular/common"
import { Component, OnInit, inject } from "@angular/core"
import { RouterModule } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"

import { PageTitleComponent } from "../../../features/global/components/page-title/page-title.component"
import { PaymentArchiveDm } from "../../../features/money/defs/types"
import { NumberCommaPipe } from "../../../features/money/pipes/number-comma.pipe"
import { PaymentsQuery } from "../../../features/money/states/payments"
import {
  PaymentsArchiveQuery,
  PaymentsArchiveService,
} from "../../../features/money/states/payments-archive"

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PageTitleComponent,
    FontAwesomeModule,
    NumberCommaPipe,
  ],
  templateUrl: "./money-archive.page.html",
  styleUrl: "./money-archive.page.scss",
})
export class MoneyArchivePageComponent implements OnInit {
  private paymentsQuery = inject(PaymentsQuery)
  private paymentsArchiveQuery = inject(PaymentsArchiveQuery)
  private paymentsArchiveService = inject(PaymentsArchiveService)

  payments$ = this.paymentsQuery.payments$
  paymentsArchive$ = this.paymentsArchiveQuery.paymentsArchive$
  isLoading$ = this.paymentsQuery.selectLoading()

  ngOnInit() {
    if (!this.paymentsArchiveQuery.initialized) {
      this.paymentsArchiveService.init()
    }
  }

  calcTotalAmount(payments: Array<PaymentArchiveDm>) {
    return payments.reduce((prev, current) => prev + current.amount, 0)
  }
}
