import { Injectable, inject } from "@angular/core"

import { UserQuery } from "../../global/states/user"
import { PaymentDm } from "../defs/types"

@Injectable({
  providedIn: "root",
})
export class DutchAmountCalculator {
  private userQuery = inject(UserQuery)

  myName = this.userQuery.name

  calc(payments: Array<PaymentDm>): number {
    let result = 0
    for (const payment of payments) {
      if (payment.adjust) {
        if (payment.userName === this.myName) {
          result += payment.amount
        } else {
          result -= payment.amount
        }
      } else {
        if (payment.userName === this.myName) {
          result += payment.amount / 2
        } else {
          result -= payment.amount / 2
        }
      }
    }
    return Number(result.toFixed(0))
  }
}
