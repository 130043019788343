import { Component, DestroyRef, OnInit, inject } from "@angular/core"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { RouterModule } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"

import { UrlChangeStreamer } from "../../../shared/services/current-url-matcher.service"

@Component({
  selector: "c-global-bottom-navi",
  standalone: true,
  imports: [RouterModule, FontAwesomeModule],
  templateUrl: "./global-bottom-navi.component.html",
  styleUrl: "./global-bottom-navi.component.scss",
})
export class GlobalBottomNaviComponent implements OnInit {
  private urlChangeStreamer = inject(UrlChangeStreamer)
  private destroyRef = inject(DestroyRef)

  canShow = true

  ngOnInit() {
    this.urlChangeStreamer.navigationEnd$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((url) => {
        if (url === "/login") {
          this.canShow = false
        } else {
          this.canShow = true
        }
      })
  }
}
