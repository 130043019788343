import { Injectable, inject } from "@angular/core"
import { Query } from "@datorama/akita"

import { AskAgainState, AskAgainStore } from "./ask-again.store"

@Injectable({
  providedIn: "root",
})
export class AskAgainQuery extends Query<AskAgainState> {
  protected override store = inject(AskAgainStore)

  // biome-ignore lint:
  constructor(store: AskAgainStore) {
    super(store)
  }
}
