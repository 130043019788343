import { Injectable, inject } from "@angular/core"
import { Query } from "@datorama/akita"
import { Observable, map } from "rxjs"

import { PaymentArchiveDm } from "../../defs/types"
import { PaymentsArchiveState, PaymentsArchiveStore } from "./payments-archive.store"

@Injectable({
  providedIn: "root",
})
export class PaymentsArchiveQuery extends Query<PaymentsArchiveState> {
  protected override store = inject(PaymentsArchiveStore)

  paymentsArchive$ = this.select(
    (paymentsArchiveState) => paymentsArchiveState.paymentsArchive,
  )

  // biome-ignore lint:
  constructor(store: PaymentsArchiveStore) {
    super(store)
  }

  get initialized(): boolean {
    return this.getValue().initialized
  }

  findById$(archiveId: string): Observable<Array<PaymentArchiveDm>> {
    return this.select(
      (paymentsArchiveState) => paymentsArchiveState.paymentsArchive,
    ).pipe(
      map((paymentsArchives) => {
        return paymentsArchives.find(
          (paymentsArchive) => paymentsArchive[0]!.archiveId === archiveId,
        )!
      }),
    )
  }
}
