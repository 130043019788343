import { UserName } from "../../global/defs/types"

export type Sort = "created" | "updated" | "played"

export interface GameDm {
  gameId: string
  title: string
  image: string
  memo?: string
  url?: string
  played: boolean
  playedAt: number
  createdBy: UserName
  createdAt: number
  updatedAt: number
}

export interface ResGameDto {
  game_id: string
  title: string
  image: string
  memo?: string
  url?: string
  played: boolean
  played_at: number
  created_by: UserName
  created_at: number
  updated_at: number
}

export interface ReqGameDto {
  title: string
  image?: string
  memo?: string
  url?: string
  created_by: UserName
}

export const transformGameResToDm = (value: ResGameDto): GameDm => {
  return {
    gameId: value.game_id,
    title: value.title,
    image: value.image,
    url: value.url,
    memo: value.memo,
    played: value.played,
    playedAt: value.played_at,
    createdBy: value.created_by,
    createdAt: value.created_at,
    updatedAt: value.updated_at,
  }
}
