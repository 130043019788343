import { Injectable, inject } from "@angular/core"

import { UserState, UserStore } from "./user.store"

@Injectable({
  providedIn: "root",
})
export class UserService {
  private userStore = inject(UserStore)

  setAll({ name, email, vapidKey, isLoggedIn }: UserState) {
    this.userStore.update({ name, email, vapidKey, isLoggedIn })
  }

  update<K extends keyof UserState>(key: K, value: unknown) {
    this.userStore.update({ [key]: value })
  }

  reset() {
    this.userStore.reset()
  }
}
