<div class="root" (blur)="onBlur()">
  <input
    type="checkbox"
    [id]="id"
    [value]="value"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
  />
  <label [for]="id">{{ label }}</label>
</div>
