import { CommonModule } from "@angular/common"
import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from "@angular/core"
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"

@Component({
  selector: "c-base-form",
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule],
  templateUrl: "./base-text-input.component.html",
  styleUrl: "./base-text-input.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseTextInputComponent),
      multi: true,
    },
  ],
})
export class BaseTextInputComponent implements OnInit, ControlValueAccessor {
  @Input() label?: string
  @Input() type?: "number" | "email" | "password" | "url" | "tel"
  @Input() id = "id-" + Math.random().toString(36).slice(2, 9)
  @Input() value?: string
  @Input() placeholder?: string
  @Input() deleteButton?: boolean

  @Output() valueChange = new EventEmitter<string>()
  @Output() focusEvent = new EventEmitter<void>()
  @Output() blurEvent = new EventEmitter<void>()

  _value!: string
  disabled = false
  onChange = (_value: string) => {}
  onTouched = () => {}

  ngOnInit() {
    if (this.value) {
      this._value = this.value
    }
  }

  writeValue(value: string) {
    this._value = value
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  onValueChange(value: string) {
    this.onChange(value)
    this.valueChange.emit(value)
  }

  onFocus() {
    this.focusEvent.emit()
  }

  onBlur() {
    this.onTouched()
    this.blurEvent.emit()
  }

  deleteText() {
    this._value = ""
    this.valueChange.emit("")
  }
}
