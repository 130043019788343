<div class="root">
  @if (label) {
    <label [for]="id">{{ label }}</label>
  }
  <textarea
    [id]="id"
    [name]="id"
    [placeholder]="placeholder ? placeholder : ''"
    [disabled]="disabled"
    [style.height]="height"
    [(ngModel)]="_value"
    (ngModelChange)="onValueChange($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
  >
  </textarea>
</div>
