<div class="root" [class.disabled]="disabled" (blur)="onBlur()">
  <div class="choice one" (click)="onClick(data[0].value)">
    {{ data[0].label }}
  </div>
  <div class="choice two" (click)="onClick(data[1].value)">
    {{ data[1].label }}
  </div>
  <div
    class="block"
    [ngClass]="{ one: data[0].value === _value, two: data[1].value === _value }"
  ></div>
</div>
