<div class="root">
  @if (!(isLoading$ | async)) {
    <div [@show]="animtaionState">
      @for (game of games$ | async; track game.gameId) {
        <div class="item" (click)="navigate(game.gameId, $event)">
          <div class="thumb">
            <img
              [src]="game.image"
              [class.generating]="game.image.includes('no-image')"
            />
            @if (game.image.includes("no-image")) {
              <c-spinner [color]="'#e77e12'" />
            }
          </div>
          <div class="content">
            <div class="title">{{ game.title }}</div>
            <div class="url">
              <a [href]="game.url" target="_blank">{{ game.url }}</a>
            </div>
            <div class="memo">
              @if (game.memo) {
                {{ game.memo }}
              } @else {
                <span class="no_memo">めもがありません</span>
              }
            </div>
          </div>
        </div>
      }
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="loading" />
  }
</div>

<ng-template #loading>
  @for (_ of 8 | repeat; track $index) {
    <div class="item loading">
      <div class="skeleton thumb"></div>
      <div class="content">
        <div class="skeleton title"></div>
        <div class="skeleton memo"></div>
      </div>
    </div>
  }
</ng-template>
