<div
  #modal
  cdkDrag
  cdkDragLockAxis="y"
  class="modal_body half_modal"
  [style.height]="halfModalHeight"
  [@show]="leaving ? 'hidden' : 'shown'"
  (@show.done)="animationDone($event)"
  (cdkDragMoved)="onDragMoved($event)"
  (cdkDragEnded)="onDragEnded($event)"
>
  <div class="handle" cdkDragHandle>
    <div class="bar"></div>
  </div>
  <button class="reset absolute" (click)="close()">
    <fa-icon icon="times" size="xl" />
  </button>
  <ng-container #container />
</div>

<ng-template #simple_t let-item="context">
  @if (item.title) {
    <div div class="title" role="heading">
      {{ item.title }}
    </div>
  }
  <div class="content simple" [innerHTML]="item.content"> </div>
  <div class="buttons">
    <c-base-button type="text" (click)="close()">Dismiss</c-base-button>
  </div>
</ng-template>

<ng-template #free_t let-item="context">
  <ng-container #free_container />
</ng-template>
